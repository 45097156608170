import { createAction } from '@cobuildlab/react-simple-state';
import {
  fetchUserErrorEvent,
  fetchUserEvent,
  OnTokenEvent,
} from './auth-events';
import axios from 'axios';
import { BACKEND_URL } from '../../shared/contants';

export const fetchCurrentUser = createAction(
  fetchUserEvent,
  fetchUserErrorEvent,
  async () => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.post(
      `${BACKEND_URL}/user/info`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { user: response.data };
  },
);
