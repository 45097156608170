import { createStoreAction } from '@cobuildlab/react-simple-state';
import { openSnackbarStore } from './snackbar-events';
import { TypeMessage } from './snackbar-types';

export const openSnackbarAction = createStoreAction(
  openSnackbarStore,
  (prev, message: string, type: TypeMessage) => ({
    ...prev,
    isOpen: true,
    message,
    type,
  }),
);

export const closeSnackbarAction = createStoreAction(
  openSnackbarStore,
  (prev) => ({
    ...prev,
    isOpen: false,
    message: '',
    type: undefined,
  }),
);
