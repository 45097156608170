import React from 'react';
import { Routes as RoutesComponent, Route } from 'react-router-dom';
import { Onboarding } from './modules/onboarding/Onboarding';
import { Auth } from './modules/auth/Auth';
import { Session } from './modules/auth/Session';
import { Layout } from './shared/components/layout/Layout';
import { OrderActivity } from './modules/order/OrderActivity';
import { Pairs } from './modules/pairs/Pairs';
import { Logout } from './modules/auth/Logout';
import { Auth0ProviderWithHistory } from './modules/auth/Auth0ProviderWithHistory';
import { Dashboard } from './modules/dashboard/Dashboard';
import { SettingLayout } from './modules/settings/components/SettingLayout';
import { SettingAccount } from './modules/settings/SettingAccount';
import { SettingStrategy } from './modules/settings/SettingStrategy';
import { SettingBroker } from './modules/settings/SettingBroker';
import { SettingRisk } from './modules/settings/SettingRisk';
import { OrderHistory } from './modules/order/OrderHistory';
import { RoleAuthorizationAdmin } from './modules/rbca/RoleAuthorization';
import { Users } from './modules/user/Users';
import { SettingBuild } from './modules/settings/SettingBuild';

export const Routes: React.FC = () => {
  return (
    <Auth0ProviderWithHistory>
      <RoutesComponent>
        <Route path="/auth" element={<Auth />} />
        <Route
          path="*"
          element={
            <Session>
              <RoutesComponent>
                <Route
                  path="/"
                  element={
                    <Layout>
                      <Dashboard />
                    </Layout>
                  }
                />

                <Route path="/onboarding" element={<Onboarding />} />

                <Route
                  path="/order-activity"
                  element={
                    <Layout>
                      <OrderActivity />
                    </Layout>
                  }
                />
                <Route
                  path="/order-history"
                  element={
                    <Layout>
                      <OrderHistory />
                    </Layout>
                  }
                />
                <Route
                  path="/pairs"
                  element={
                    <Layout>
                      <Pairs />
                    </Layout>
                  }
                />

                <Route
                  path="/setting/account"
                  element={
                    <Layout>
                      <SettingLayout>
                        <SettingAccount />
                      </SettingLayout>
                    </Layout>
                  }
                />

                <Route
                  path="/setting/strategy"
                  element={
                    <Layout>
                      <RoleAuthorizationAdmin
                        render={() => (
                          <SettingLayout>
                            <SettingStrategy />
                          </SettingLayout>
                        )}
                      />
                    </Layout>
                  }
                />
                <Route
                  path="/setting/platform-status"
                  element={
                    <Layout>
                      <RoleAuthorizationAdmin
                        render={() => (
                          <SettingLayout>
                            <SettingBuild />
                          </SettingLayout>
                        )}
                      />
                    </Layout>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <Layout>
                      <RoleAuthorizationAdmin render={() => <Users />} />
                    </Layout>
                  }
                />
                <Route
                  path="/setting/broker-connection"
                  element={
                    <Layout>
                      <SettingLayout>
                        <SettingBroker />
                      </SettingLayout>
                    </Layout>
                  }
                />
                <Route
                  path="/setting/risk-management"
                  element={
                    <Layout>
                      <SettingLayout>
                        <SettingRisk />
                      </SettingLayout>
                    </Layout>
                  }
                />
                <Route path="/logout" element={<Logout />} />
              </RoutesComponent>
            </Session>
          }
        />
      </RoutesComponent>
    </Auth0ProviderWithHistory>
  );
};
