import { createAction } from '@cobuildlab/react-simple-state';
import { fetchUserEvent, OnTokenEvent } from '../auth/auth-events';
import axios from 'axios';
import {
  createUserRiskErrorEvent,
  createUserRiskEvent,
  fetchUsersErrorEvent,
  fetchUsersEvent,
  updateUserErrorEvent,
  updateUserEvent,
  updateUserRiskErrorEvent,
  updateUserRiskEvent,
} from './user-events';
import { UpdateUserInput } from './user-types';
import { BACKEND_URL } from '../../shared/contants';
import { Risk } from '../risk/risk-types';
import { User } from '../auth/auth-types';

export const updateUser = createAction(
  updateUserEvent,
  updateUserErrorEvent,
  async (data: UpdateUserInput) => {
    const token = OnTokenEvent.get()?.token;
    const user = fetchUserEvent.get()?.user;
    const response = await axios.post(
      `${BACKEND_URL}/user/update/${user?.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { user: response.data };
  },
);

export const createUserRisk = createAction(
  createUserRiskEvent,
  createUserRiskErrorEvent,
  async (data: Risk) => {
    const token = OnTokenEvent.get()?.token;
    const user = fetchUserEvent.get()?.user;
    const response = await axios.post(
      `${BACKEND_URL}/user/risk/create`,
      {
        userId: user?.id,
        riskId: data.id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { user: response.data };
  },
);

export const updateUserRisk = createAction(
  updateUserRiskEvent,
  updateUserRiskErrorEvent,
  async (data: Risk) => {
    const token = OnTokenEvent.get()?.token;
    const user = fetchUserEvent.get()?.user;
    const response = await axios.post(
      `${BACKEND_URL}/user/risk/update`,
      {
        userId: user?.id,
        risk:data
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { risk: response.data.risk };
  },
);

export const fetchUsers = createAction(
  fetchUsersEvent,
  fetchUsersErrorEvent,
  async () => {
    const token = OnTokenEvent.get()?.token;

    const response = await axios.get<{ users: User[] }>(
      `${BACKEND_URL}/user/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  },
);
