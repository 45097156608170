import { SetupAuth0Token } from './auth-types';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createAction,
  useCallAction,
  useEvent,
  useSubscription,
} from '@cobuildlab/react-simple-state';
import {
  fetchUserErrorEvent,
  fetchUserEvent,
  OnTokenErrorEvent,
  OnTokenEvent,
} from './auth-events';
import { fetchCurrentUser } from './auth-actions';

export const useSetupAuth0Token = (): SetupAuth0Token => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenReady, setIsTokenReady] = useState(false);
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    const tokenAction = createAction(
      OnTokenEvent,
      OnTokenErrorEvent,
      async () => {
        const newAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://ihuw.us.auth0.com/api/v2/',
            email: user?.email,
          },
        });
        // eslint-disable-next-line no-underscore-dangle
        return { token: newAccessToken };
      },
    );

    if (isAuthenticated) {
      setIsLoading(true);
      tokenAction();
    }
  }, [getAccessTokenSilently, isAuthenticated, user?.email]);

  useSubscription(OnTokenEvent, () => {
    setIsTokenReady(true);
    setIsLoading(false);
  });

  return { isLoading, isTokenReady };
};

export const useSession = (): {
  loading: boolean;
  sessionError: string;
} => {
  const { token } = useEvent(OnTokenEvent);
  const [loading, setLoading] = useState<boolean>(true);
  const [sessionError, setSessionError] = useState<string>('');
  const [callCurrentUser] = useCallAction(fetchCurrentUser);
  useEffect(() => {
    if (token) callCurrentUser();
  }, [callCurrentUser, token]);

  useSubscription(fetchUserEvent, () => {
    setLoading(false);
  });

  useSubscription(fetchUserErrorEvent, () => {
    setSessionError('Session Error');
  });

  return {
    loading,
    sessionError,
  };
};
