import React, { ReactNode, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../shared/components/Loading';
import { TokenSession } from './TokenSession';

type SessionProps = {
  children?: ReactNode;
};
export const Session: React.FC<SessionProps> = ({ children }) => {
  const { isAuthenticated, isLoading, error, logout } = useAuth0();
  useEffect(() => {
    if (!error) return;
    const timeOut = setTimeout(() => {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }, 5000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [error, logout]);
  const navigate = useNavigate();

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <Box>We have problem</Box>
        <Box>Will be redirect to login</Box>
      </Box>
    );
  }
  if (isLoading) return <Loading />;
  if (!isAuthenticated) {
    navigate('auth', { state: { redirectTo: window.location.href } });
  }

  return <TokenSession>{children}</TokenSession>;
};
