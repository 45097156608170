import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import {
  MetatraderAccount,
  MetatraderSymbolSpecification,
  MetatraderSymbolPrice,
} from 'metaapi.cloud-sdk';
import { User } from '../auth/auth-types';
import { AxiosError } from 'axios';
import TerminalState from 'metaapi.cloud-sdk/dists/esm/metaApi/terminalState';
import { MetatraderPosition } from 'metaapi.cloud-sdk/dist/clients/metaApi/metaApiWebsocket.client';
import { MetaStatMetric, MetaStatTradesMetric } from './meta-api-cloud-types';
import { META_STAT_TRADES } from './meta-api-cloud-constants';

export const createMetaApiAccountEvent = createEvent<{
  account: MetatraderAccount | undefined;
}>({
  initialValue: undefined,
});

export const createMetaApiAccountErrorEvent =
  createEvent<AxiosError<{ message: string }>>();

export const updateMetaApiAccountEvent = createEvent<{
  user: User | undefined;
}>({
  initialValue: undefined,
});

export const updateMetaApiAccountErrorEvent =
  createEvent<AxiosError<{ message: string }>>();

export const updateMetaApiBrokerEvent = createEvent<{
  account: MetatraderAccount | undefined;
}>({
  initialValue: undefined,
});

export const updateMetaApiBrokerErrorEvent =
  createEvent<AxiosError<{ message: string }>>();

export const fetchSymbolSpecificationStore = createStore<{
  symbolSpecifications: Array<MetatraderSymbolSpecification>;
}>({
  initialValue: {
    symbolSpecifications: [],
  },
});

export const fetchSymbolPriceStore = createStore<{
  price: MetatraderSymbolPrice | undefined;
}>({
  initialValue: {
    price: undefined,
  },
});

export const fetchMetaApiCloudAccountEvent = createEvent<{
  account: MetatraderAccount | undefined;
}>({
  initialValue: {
    account: undefined,
  },
});

export const fetchMetaApiCloudTerminalStateEvent = createEvent<{
  terminalState: TerminalState | undefined;
}>({
  initialValue: {
    terminalState: undefined,
  },
});

export const fetchMetaApiCloudPositionsEvent = createEvent<{
  positions: MetatraderPosition[];
}>({
  initialValue: {
    positions: [],
  },
});

export const fetchMetaStatTradesMetricEvent = createEvent<MetaStatTradesMetric>({
  initialValue:META_STAT_TRADES,
});

export const fetchMetaStatTradesErrorMetricEvent = createEvent<Error>();

export const fetchMetaStatMetricsEvent = createEvent<{
  metrics: MetaStatMetric | undefined;
}>({
  initialValue: {
    metrics: undefined,
  },
});

export const fetchMetaStatMetricsErrorEvent = createEvent();
