import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import moment, { Moment } from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeStrategyStore } from '../../strategy/strategy-events';
import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, IconButton, Chip } from '@mui/material';
import {
  changeStrategyAction,
  createStrategyTime,
  deleteStrategyTime,
  fetchStrategies,
} from '../../strategy/strategy-actions';
import CloseIcon from '@mui/icons-material/Close';
import { Strategy } from '../../strategy/strategy-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& > .MuiStack-root': {
      paddingTop: 0,
      flexDirection: 'row',
      '& > .MuiFormControl-root': {
        marginTop: 0,
        marginRight: 10,
      },
    },
  },
}));

export const StrategyTime: React.FC = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState<Moment | null>(moment());
  const [endDate, setEndDate] = React.useState<Moment | null>(moment());
  const { strategy } = useStore(changeStrategyStore);

  const [callFetchStrategies] = useCallAction(fetchStrategies, {
    onCompleted: ({ strategies }) => {
      const newStrategy = strategies.find(
        (_strategy: Strategy) => _strategy.id === strategy?.id,
      );

      changeStrategyAction(newStrategy);
    },
  });

  const [callDeleteStrategyTime] = useCallAction(deleteStrategyTime, {
    onCompleted: () => {
      callFetchStrategies();
    },
  });

  const [callStrategyTime] = useCallAction(createStrategyTime, {
    onCompleted: () => {
      callFetchStrategies();
    },
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <Box display="flex" height="100%" alignItems="center">
              <Box className={classes.root}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DemoContainer components={['TimeField', 'TimeField']}>
                    <TimeField
                      defaultValue={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      size="small"
                      style={{ width: 100, minWidth: 100 }}
                    />
                    <TimeField
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      size="small"
                      style={{ width: 100, minWidth: 100 }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box
                display="flex"
                height="100%"
                justifyContent="center"
                alignItems="center"
                component="span"
              >
                <IconButton
                  onClick={() =>
                    callStrategyTime({
                      startDate: startDate?.format('hh:mm A'),
                      endDate: endDate?.format('hh:mm A'),
                      strategyId: strategy?.id,
                    })
                  }
                >
                  <AddIcon sx={{ color: 'rgb(32, 125, 50)' }} />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box overflow="auto">
              <Box display="flex" sx={{ padding: { xs: '10px 0', md: 0 } }}>
                {strategy?.time?.map((t) => (
                  <Box mr={1}>
                    <Chip
                      variant="outlined"
                      label={`${t.startDate} - ${t.endDate}`}
                      deleteIcon={
                        <CloseIcon sx={{ color: '#000!important' }} />
                      }
                      onDelete={() => callDeleteStrategyTime({ id: t.id })}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
