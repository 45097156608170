import * as React from 'react';

import { Typography, Theme, Box } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { createStyles, makeStyles } from '@mui/styles';

type Dropdown1Props = {
  options: string[];
  setSelectedIndex: (value: number | ((prevVar: number) => number)) => void;
  selectedIndex: number;
};
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',
      height: 30,
      width: 30,
      margin: '0 0',
    },
    eventsNone: {
      pointerEvents: 'none',
    },
  }),
);
export const Dropdown1: React.FC<Dropdown1Props> = ({
  options,
  setSelectedIndex,
  selectedIndex,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ): void => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = (): void => {
    setOpen(!open);
  };

  const handleClose = (event: Event): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const classes = useStyles();
  return (
    <Box position={'relative'}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Typography display={'flex'} alignItems={'center'}>
          {options[selectedIndex]}
          <button className={classes.cleanButton} onClick={handleToggle}>
            <ArrowDropDownIcon fontSize="small" />
          </button>
        </Typography>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        style={{ position: 'absolute', top: 30 }}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
