import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  MetatraderSymbolSpecification,
  NewMetatraderAccountDto,
  MetaStats,
} from 'metaapi.cloud-sdk';
import { MetatraderAccount } from 'metaapi.cloud-sdk';

import {
  createMetaApiAccountErrorEvent,
  createMetaApiAccountEvent,
  fetchMetaStatMetricsErrorEvent,
  fetchMetaStatMetricsEvent,
  fetchMetaStatTradesErrorMetricEvent,
  fetchMetaStatTradesMetricEvent,
  fetchSymbolPriceStore,
  fetchSymbolSpecificationStore,
  updateMetaApiAccountErrorEvent,
  updateMetaApiAccountEvent,
  updateMetaApiBrokerErrorEvent,
  updateMetaApiBrokerEvent,
} from './meta-api-cloud-events';
import axios from 'axios';
import { fetchUserEvent, OnTokenEvent } from '../auth/auth-events';
import { BACKEND_URL, META_API_TOKEN } from '../../shared/contants';
import { User } from '../auth/auth-types';
import {
  MetaStatMetric,
  MetaStatTrade,
  MetaStatTradeResponse,
  MetaStatTradeSuccessType,
  UpdateMetaApiBrokerData,
} from './meta-api-cloud-types';
import MetaApi, {
  MetatraderSymbolPrice,
  StreamingMetaApiConnectionInstance,
} from 'metaapi.cloud-sdk';
import { MetaApiListener } from './MetaApiListener';
import moment from 'moment';
import {
  accumulateTrades,
  calculateVariation,
  getBestTrade,
  groupTradesByCurrency,
} from './meta-api-cloud-utils';
import { META_STAT_TRADES } from './meta-api-cloud-constants';

export const connectToMetaApi = async (
  accountId: string,
): Promise<StreamingMetaApiConnectionInstance> => {
  // Get instance of MetaApi with your MetaApi token
  const metaApi = new MetaApi(META_API_TOKEN, {
    region: 'new-york',
    domain: 'agiliumtrade.agiliumtrade.ai',
  });

  // Get MetaTrader account
  const account = await metaApi.metatraderAccountApi.getAccount(
    accountId as string,
  );
  const initialState = account.state;
  const deployedStates = ['DEPLOYING', 'DEPLOYED'];

  if (!deployedStates.includes(initialState)) {
    // wait until account is deployed and connected to broker
    await account.deploy();
  }

  // Get connection instance
  await account.waitConnected();

  const connection = account.getStreamingConnection();
  const listener = new MetaApiListener();
  connection.addSynchronizationListener(listener);
  // Wait until connection is established
  await connection.connect();
  await connection.waitSynchronized();
  return connection;
};

export const createMetaApiAccount = createAction(
  createMetaApiAccountEvent,
  createMetaApiAccountErrorEvent,
  async (data: NewMetatraderAccountDto) => {
    const token = OnTokenEvent.get()?.token;
    const user = fetchUserEvent.get()?.user;

    const account = await axios.post<MetatraderAccount>(
      `${BACKEND_URL}/meta-api-cloud/create`,
      {
        userId: user?.id,
        account: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return {
      account: account.data,
    };
  },
);

export const updateMetaApiAccount = createAction(
  updateMetaApiAccountEvent,
  updateMetaApiAccountErrorEvent,
  async (data: NewMetatraderAccountDto) => {
    const token = OnTokenEvent.get()?.token;
    const user = fetchUserEvent.get()?.user;

    const response = await axios.post<User>(
      `${BACKEND_URL}/meta-api-cloud/update`,
      {
        userId: user?.id,
        account: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return {
      user: response.data,
    };
  },
);

export const updateMetaApiBroker = createAction(
  updateMetaApiBrokerEvent,
  updateMetaApiBrokerErrorEvent,
  async (data: UpdateMetaApiBrokerData) => {
    const token = OnTokenEvent.get()?.token;

    const user = fetchUserEvent.get()?.user;

    const account = await axios.post<MetatraderAccount>(
      `${BACKEND_URL}/meta-api-cloud/update-broker`,
      {
        userId: user?.id,
        account: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return {
      account: account.data,
    };
  },
);

export const fetchSymbolSpecificationsStoreAction = createStoreAction(
  fetchSymbolSpecificationStore,
  (prev, symbolSpecifications: Array<MetatraderSymbolSpecification>) => ({
    ...prev,
    symbolSpecifications,
  }),
);

export const fetchSymbolPricesAction = createStoreAction(
  fetchSymbolPriceStore,
  (prev, symbolPrices: Array<MetatraderSymbolPrice>) => ({
    ...prev,
    symbolPrices,
  }),
);

export const metaStatsOpenTrades = async (): Promise<MetaStatTrade[]> => {
  const user = fetchUserEvent.get()?.user;
  const accountId = user?.MetaApiCloudAccount
    ? user?.MetaApiCloudAccount.accountId
    : undefined;
  let trades: MetaStatTrade[] = [];

  try {
    const startDate = moment()
      .utc(true)
      .subtract(1, 'day')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss.SSS');
    const endDate = moment()
      .utc(true)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss.SSS');
    const response = await axios.get<MetaStatTradeResponse>(
      `https://metastats-api-v1.new-york.agiliumtrade.ai/users/current/accounts/${accountId}/historical-trades/${startDate}/${endDate}?updateHistory=true&limit=1000&offset=0`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'auth-token': META_API_TOKEN,
        },
      },
    );
    trades = response.data.trades || [];
  } catch (err) {
    console.error(err);
  }

  return trades;
};

export const fetchMetaStatsMetrics = async (): Promise<
  MetaStatMetric | undefined
> => {
  const user = fetchUserEvent.get()?.user;
  const accountId = user?.MetaApiCloudAccount
    ? user?.MetaApiCloudAccount.accountId
    : undefined;
  let metrics: MetaStatMetric | undefined = undefined;

  try {
    const response = await axios.get<{ metrics: MetaStatMetric }>(
      `https://metastats-api-v1.new-york.agiliumtrade.ai/users/current/accounts/${accountId}/metrics`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'auth-token': META_API_TOKEN,
        },
      },
    );

    metrics = response.data.metrics;

    console.log({ metrics });
  } catch (err) {
    console.error(err);
  }

  return metrics;
};

export const metaStatsPositions = async (
  accountId?: string,
): Promise<MetaStatTrade[]> => {
  const metaStats = new MetaStats(META_API_TOKEN);
  let trades = [];
  try {
    trades = await metaStats.getAccountOpenTrades(accountId);
  } catch (err) {
    console.error(err);
  }

  return trades || [];
};

export const metaStatsMetrics = async (
  accountId?: string,
): Promise<MetaStatMetric> => {
  const metaStats = new MetaStats(META_API_TOKEN);
  let trades = [];
  try {
    trades = await metaStats.getMetrics(accountId);
  } catch (err) {
    console.error(err);
  }

  return trades || [];
};

export const fetchMetaStatTradesMetric = createAction(
  fetchMetaStatTradesMetricEvent,
  fetchMetaStatTradesErrorMetricEvent,
  async () => {
    const today = moment().utc(true).format('YYYY-MM-DD');
    const yesterday = moment()
      .utc(true)
      .subtract(1, 'day')
      .format('YYYY-MM-DD');

    const trades = await metaStatsOpenTrades();

    if (!trades.length) {
      console.log('DEBUG');
      return {
        ...META_STAT_TRADES,
        ...fetchMetaStatMetricsEvent.get(),
      };
    }

    const tradesToday = trades.filter(
      (trade) => moment(trade.closeTime).format('YYYY-MM-DD') === today,
    );
    const tradesYesterday = trades.filter(
      (trade) => moment(trade.closeTime).format('YYYY-MM-DD') === yesterday,
    );

    const tradesCloseInProfit = accumulateTrades(
      tradesToday,
      MetaStatTradeSuccessType.won,
    );
    const tradesCloseInLose = accumulateTrades(
      tradesToday,
      MetaStatTradeSuccessType.lost,
    );

    const tradesCloseInProfitDifference = accumulateTrades(
      tradesYesterday,
      MetaStatTradeSuccessType.won,
    );
    const tradesCloseInLoseDifference = accumulateTrades(
      tradesYesterday,
      MetaStatTradeSuccessType.lost,
    );

    const groupCurrenciesToday = groupTradesByCurrency(tradesToday);
    const groupCurrenciesYesterday = groupTradesByCurrency(tradesYesterday);

    const bestPair = getBestTrade(groupCurrenciesToday);
    const bestPairDifference = groupCurrenciesYesterday.find(
      ({ currency }) => currency === bestPair.currency,
    );

    const variationPair = calculateVariation(
      bestPair.profit || 0,
      bestPairDifference?.profit || 0,
    );
    const variationTradeCloseInProfit = calculateVariation(
      tradesCloseInProfit,
      tradesCloseInProfitDifference,
    );
    const variationTradesCloseInLose = calculateVariation(
      tradesCloseInLose,
      tradesCloseInLoseDifference,
    );

    return {
      tradesCloseInProfit,
      tradesCloseInLose,
      tradesCloseInLoseDifference,
      tradesCloseInProfitDifference,
      variationPair,
      bestPair,
      bestPairDifference,
      variationTradeCloseInProfit,
      variationTradesCloseInLose,
    };
  },
);

export const fetchMetaStatMetrics = createAction(
  fetchMetaStatMetricsEvent,
  fetchMetaStatMetricsErrorEvent,
  async () => {
    const metrics = await fetchMetaStatsMetrics();


    if (!metrics) return {
      metrics: fetchMetaStatMetricsEvent.get()?.metrics,
    };
    return {
      metrics,
    };
  },
);
