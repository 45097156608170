import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import momentTimezone from 'moment-timezone';

const TIMEZONE = 'America/New_York';
const FORMAT = 'LT';
const generateTime = (): string => {
  return momentTimezone().tz(TIMEZONE).format(FORMAT);
};

export const Time: React.FC = () => {
  const [time, setTime] = useState(generateTime());

  useEffect(() => {
    setInterval(() => {
      setTime(generateTime());
    }, 1000);
  }, []);

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box display={'flex'}>
        <AccessTimeIcon fontSize='medium' />
        <Typography paddingLeft={'5px'} fontWeight={700}>{time}</Typography>
      </Box>
    </Box>
  );
};
