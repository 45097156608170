import { createEvent } from '@cobuildlab/react-simple-state';
import { MetaStatMetric, MetaStatTradesMetric } from '../meta-api-cloud/meta-api-cloud-types';
import { META_STAT_TRADES } from '../meta-api-cloud/meta-api-cloud-constants';


export const fetchMetricsEvent = createEvent<{
  trade: MetaStatTradesMetric,
  metrics: MetaStatMetric | undefined
}>({
  initialValue: {
    trade: META_STAT_TRADES,
    metrics: undefined,
  },
});

export const fetchMetricsErrorEvent = createEvent();