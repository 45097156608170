import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  MetaStatTrade,
  MetaStatTradeDealType,
  MetaStatTradeSuccessType,
} from '../../meta-api-cloud/meta-api-cloud-types';
import { calculateTradeMarginProfit } from '../order-utils';
import momentTimezone from 'moment-timezone';
import {
  ORDER_HISTORY_LONG_COLUMNS,
  ORDER_HISTORY_SHORT_COLUMNS,
} from '../order-constants';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeOrderHistoryViewStore } from '../order-stores';
import { OrderField } from '../order-types';

type OrderHistoryItemProps = {
  trade: MetaStatTrade;
};

export const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({
                                                                    trade,
                                                                  }) => {
  const { short } = useStore(changeOrderHistoryViewStore);

  const columns = short ? ORDER_HISTORY_SHORT_COLUMNS : ORDER_HISTORY_LONG_COLUMNS;

  const showPair = columns.find((column) => column.field === OrderField.Pair);
  const showDate = columns.find((column) => column.field === OrderField.Date);
  const showPosition = columns.find((column) => column.field === OrderField.Position);
  const showPips = columns.find((column) => column.field === OrderField.Pips);
  const showAmount = columns.find((column) => column.field === OrderField.Amount);
  const showProfit = columns.find((column) => column.field === OrderField.Profit);

  return (
    <Grid container py={'5px'} borderBottom={'1px solid #F0F0F0'} px={'15px'}>
      {
        showPair && (
          <Grid item xs={showPair.xs} >
            <Typography fontWeight={600} textAlign='center'>
              {trade.symbol}
              {', '}
              <span
                style={{
                  color:
                    trade.type === MetaStatTradeDealType.DealTypeBuy
                      ? '#186FF3'
                      : '#E04029',
                }}
              >{`${
                trade.type === MetaStatTradeDealType.DealTypeBuy ? 'buy' : 'sell '
              } ${trade.volume}`}</span>
            </Typography>
          </Grid>
        )
      }
      {
        showDate && (
          <Grid item xs={showDate.xs}>
            <Typography fontWeight={600} textAlign='center'>
              {momentTimezone(new Date(trade.closeTime as string).getTime())
                .tz('America/New_York').format('LLL')}
            </Typography>
          </Grid>
        )
      }

      {
        showPosition && (
          <Grid item xs={showPosition.xs}>
            <Typography textAlign={'center'} fontWeight={600} color={'#7E7E7E'}>
              {`${trade.openPrice}  >  ${trade.closePrice}`}
            </Typography>
          </Grid>
        )
      }

      {
        showPips && (
          <Grid item xs={showPips.xs}>
            <Typography
              textAlign={'center'}
              fontWeight={600}
              color={
                trade.success === MetaStatTradeSuccessType.won
                  ? '#186FF3'
                  : '#E04029'
              }
            >
              {trade.pips}
            </Typography>
          </Grid>
        )
      }
      {
        showAmount && (
          <Grid item xs={showAmount.xs}>
            <Typography fontWeight={600} textAlign={'center'}>
          <span
            style={{
              color:
                trade.success === MetaStatTradeSuccessType.won
                  ? '#186FF3'
                  : '#E04029',
            }}
          >
            {trade?.profit?.toFixed(2)}
          </span>
            </Typography>
          </Grid>
        )
      }

      {
        showProfit && (
          <Grid item xs={2}>
            <Typography fontWeight={600} textAlign={'center'}>
          <span
            style={{
              color:
                trade.success === MetaStatTradeSuccessType.won
                  ? '#186FF3'
                  : '#E04029',
            }}
          >
            {trade.success === MetaStatTradeSuccessType.lost ? '-' : ''}
            {calculateTradeMarginProfit(trade).toFixed(2)} %
          </span>
            </Typography>
          </Grid>
        )
      }


    </Grid>
  );
};
