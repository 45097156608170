import { StrategyType } from '../strategy/strategy-types';

export const SETTING_ROUTES = [
  {
    path: '/setting/account',
    name: 'My Account',
    checkRole: false,
  },
  {
    path: '/setting/strategy',
    name: 'Strategy',
    checkRole: true,
  },
  {
    path: '/setting/broker-connection',
    name: 'Broker Connection',
    checkRole: false,
  },
  {
    path: '/setting/risk-management',
    name: 'Risk Management',
    checkRole: false,
  },
  {
    path: '/setting/platform-status',
    name: 'Platform Status',
    checkRole: false,
  },
];

export const getStrategyType = (
  strategyType: StrategyType | undefined,
): string => {
  let strategy: string;
  switch (strategyType) {
    case StrategyType.AGAINST:
      strategy = 'Against Movement';
      break;
    case StrategyType.FAVOR:
      strategy = 'Favor Movement';
      break;
    case StrategyType.SAFETY_LOSS:
      strategy = 'Safety Loss';
      break;
    case StrategyType.CYCLES:
      strategy = 'Cycles';
      break;
    case StrategyType.RED_AGAINST:
      strategy = 'Red against';
      break;
    default:
      strategy = 'Safety Profit';
      break;
  }

  return strategy;
};

export const getDecimals = (num: number): number => {
  const stringNumberArray = num.toString().split('.');
  if (stringNumberArray.length && stringNumberArray[1]) {
    return stringNumberArray[1].length;
  }
  return 0;
};
