import { MetatraderSymbolSpecification } from 'metaapi.cloud-sdk/dists/esm';
import { fetchStrategiesEvent } from '../strategy/strategy-events';
import {
  GroupTradesByCurrency,
  MetaStatTrade,
  MetaStatTradeSuccessType,
} from './meta-api-cloud-types';

export const symbolSpecificationsFilter = (
  symbolSpecifications: Array<MetatraderSymbolSpecification>,
): Array<MetatraderSymbolSpecification> => {
  const strategies = fetchStrategiesEvent.get()?.strategies;

  return symbolSpecifications.filter((symbolSpecification) => {
    return strategies?.find(
      (symbolSpecificationAllowed) =>
        symbolSpecificationAllowed.symbol === symbolSpecification.symbol,
    );
  });
};

export const accumulateTrades = (
  trades: MetaStatTrade[],
  success: MetaStatTradeSuccessType,
): number => {
  console.log({ trades });
  return trades.reduce((accTrade, currentTrade) => {
    if (currentTrade.success == success) {
      return accTrade + 1;
    }
    return accTrade;
  }, 0);
};

export const groupTradesByCurrency = (
  trades: MetaStatTrade[],
): GroupTradesByCurrency[] => {
  const data: GroupTradesByCurrency[] = [];
  trades.forEach((trade) => {
    const symbol = data.find((sym) => sym.currency === trade.symbol);

    if (symbol) {
      symbol.profit = (trade.profit || 0) + symbol.profit;
      symbol.tradesCount = symbol.tradesCount + 1;
    } else {
      data.push({
        tradesCount: 1,
        profit: trade.profit || 0,
        currency: trade.symbol as string,
        closeTime: trade.closeTime as string,
      });
    }
  });

  return data;
};

export const getBestTrade = (
  group: GroupTradesByCurrency[],
): GroupTradesByCurrency => {
  return group.reduce(
    (prevGroup, currentGroup) => {
      if (currentGroup.tradesCount > prevGroup.tradesCount) {
        return currentGroup;
      }

      return prevGroup;
    },
    { tradesCount: 0, profit: 0, currency: '', closeTime: '' },
  );
};

export const calculateVariation = (value = 0, differenceValue = 0): number => {
  if (!differenceValue) return 0;

  return 100 * ((value - differenceValue) / Math.abs(differenceValue));
};
