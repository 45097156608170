export enum MetaApiCloudAccountSteps {
  CreateAccount = 'CREATE_ACCOUNT',
  SuccessAccount = 'SUCCESS_ACCOUNT',
}

export type UpdateMetaApiBrokerData = {
  password: string;
};

export enum PositionType {
  PositionTypeBuy = 'POSITION_TYPE_BUY',
  PositionTypeSell = 'POSITION_TYPE_SELL',
}

export enum MetaStatTradeDealType {
  DealTypeBuy = 'DEAL_TYPE_BUY',
  DealTypeSell = 'DEAL_TYPE_SELL',
  DealTypeBalance = 'DEAL_TYPE_BALANCE',
}

export enum MetaStatTradeSuccessType {
  won = 'won',
  lost = 'lost',
}

export type MetaStatTradeResponse = {
  state?: 'processing';
  trades?: [MetaStatTrade];
};

export type MetaStatTrade = {
  _id?: string;
  accountId?: string;
  volume?: number;
  durationInMinutes: number;
  profit?: number;
  gain?: number;
  success?: MetaStatTradeSuccessType;
  openTime?: string;
  type?: MetaStatTradeDealType;
  symbol?: string;
  closeTime?: string;
  openPrice?: number;
  closePrice?: number;
  pips?: number;
};

export type MetaStatMetricPeriod = {
  gain?: number;
  gainDifference?: number;
  lots?: number;
  lotsDifference?: number;
  pips?: number;
  pipsDifference?: number;
  profit?: number;
  profitDifference?: number;
  trades?: number;
  tradesDifference?: number;
  wonTradesPercent?: number;
  wonTradesPercentDifference?: number;
};

export type CurrencySummaryHistoryDayMetric = {
  date?: string;
  totalProfit?: number;
  totalPips?: number;
  shortProfit?: number;
  longProfit?: number;
  shortPips?: number;
  longPips?: number;
};

export type CurrencySummaryTotalMetric = {
  wonTrades?: number;
  lostTrades?: number;
  trades?: number;
  profit?: number;
  pips?: number;
  wonTradesPercent?: number;
  lostTradesPercent?: number;
};

export type CurrencySummaryTradeMetric = {
  trades?: number;
  profit?: number;
  pips?: number;
};

export type CurrencySummaryMetric = {
  currency?: string;
  history?: [CurrencySummaryHistoryDayMetric];
  total?: CurrencySummaryTotalMetric;
  short?: CurrencySummaryTradeMetric;
  long?: CurrencySummaryTradeMetric;
};

export type MetaStatMetric = {
  equity?: number;
  balance?: number;
  marginLevel?: number;
  margin?: number;
  profit?: number;
  periods: {
    thisMonth?: MetaStatMetricPeriod;
    thisWeek?: MetaStatMetricPeriod;
    thisYear?: MetaStatMetricPeriod;
    today?: MetaStatMetricPeriod;
  };
  currencySummary?: [CurrencySummaryMetric];
};

export type GroupTradesByCurrency = {
  tradesCount: number;
  profit: number;
  currency: string;
  closeTime: string;
};

export type MetaStatTradesMetric = {
  tradesCloseInLose: number;
  tradesCloseInProfit: number;
  tradesCloseInLoseDifference: number;
  tradesCloseInProfitDifference: number;
  variationTradesCloseInLose: number;
  variationTradeCloseInProfit: number;
  variationPair: number;
  bestPair: GroupTradesByCurrency;
  bestPairDifference?: GroupTradesByCurrency;
}
