export type ScheduleType = {
  times: {
    startDate: string;
    endDate: string;
  };
  city: string;
  image: string;
  addDays?: number;
};

export type TradingVolumenType = {
  times: {
    startDate: string;
    endDate: string;
  };
  volumen: TradingVolumenEnum;
  addDays?: number;
};

export enum TradingVolumenEnum {
  Medium = 'Medium',
  Low = 'Low',
  High = 'High',
}
