export type UpdateUserInput = {
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
};

export type UserMetaApiAccount = {
  accountId?: string;
  currency?: string;
  login?: string;
  id?: number;
  server?: string;
  deleted?: boolean;
  userId?: number;
  version?: number;
};

export enum UserRole {
  ADMIN = 'ADMIN',
  GUEST = 'GUEST',
}
