import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { MetatraderPosition } from 'metaapi.cloud-sdk/dist/clients/metaApi/metaApiWebsocket.client';
import { PositionType } from '../../meta-api-cloud/meta-api-cloud-types';
import { makeStyles } from '@mui/styles';
import { calculateMarginProfit } from '../order-utils';
import { useMetaCloudHook } from '../../meta-api-cloud/meta-api-cloud-hooks';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeActivityViewStore } from '../order-stores';
import {
  ORDER_ACTIVITY_LONG_COLUMNS,
  ORDER_ACTIVITY_SHORT_COLUMNS,
} from '../order-constants';
import { OrderField } from '../order-types';

const useStyles = makeStyles(() => ({
  text: {
    color: '#186FF3',
  },
}));

type OrderActivityItemProps = {
  position: MetatraderPosition;
};

export const OrderActivityItem: React.FC<OrderActivityItemProps> = ({
                                                                      position,
                                                                    }) => {
  const classes = useStyles();
  const { connection } = useMetaCloudHook();
  const { short } = useStore(changeActivityViewStore);

  const columns = short ? ORDER_ACTIVITY_SHORT_COLUMNS : ORDER_ACTIVITY_LONG_COLUMNS;

  const showPair = columns.find((column) => column.field === OrderField.Pair);
  const showAmount = columns.find((column) => column.field === OrderField.Amount);
  const showPosition = columns.find((column) => column.field === OrderField.Position);
  const showProfit = columns.find((column) => column.field === OrderField.Profit);
  const showAction = columns.find((column) => column.field === OrderField.Action);

  return (
    <Grid container py={'5px'} borderBottom={'1px solid #F0F0F0'} px={'15px'}>
      {
        showPair && (
          <Grid item xs={showPair?.xs}>
            <Typography fontWeight={600} textAlign={'center'}>
              {position.symbol}
              {', '}
              <span
                className={classes.text}
                style={{
                  color:
                    position.type === PositionType.PositionTypeBuy
                      ? '#186FF3'
                      : '#E04029',
                }}
              >{`${
                position.type === PositionType.PositionTypeBuy ? 'buy' : 'sell '
              } ${position.volume}`}</span>
            </Typography>
          </Grid>
        )
      }

      {
        showPosition && (
          <Grid item xs={showPosition.xs}>
            <Typography textAlign={'center'} fontWeight={600} color={'#7E7E7E'}>
              {`${position.openPrice}  >  ${position.currentPrice.toFixed(2)}`}
            </Typography>
          </Grid>
        )
      }

      {
        showAmount && (
          <Grid item xs={showAmount.xs}>
            <Typography fontWeight={600} textAlign={'center'}>
          <span style={{ color: position.profit > 0 ? '#186FF3' : '#E04029' }}>
            {position.profit.toFixed(2)}
          </span>
            </Typography>
          </Grid>
        )
      }
      {
        showProfit && (
          <Grid item xs={showProfit.xs}>
            <Typography fontWeight={600} textAlign={'center'}>
          <span style={{ color: position.profit > 0 ? '#186FF3' : '#E04029' }}>
            {`${position.profit < 0 ? '-' : ''}${calculateMarginProfit(
              position,
            ).toFixed(2)}%`}
          </span>
            </Typography>
          </Grid>
        )
      }


      <Grid item xs={showAction?.xs}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => connection?.closePosition(position.id.toString(), {})}
        >
          <Chip
            label={position.profit > 0 ? 'Take Profit' : 'Take Loss'}
            style={{
              color: 'white',
              background: position.profit > 0 ? '#30b649' : '#E04029',
              width: 110,
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
