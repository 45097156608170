import React, { useEffect, useState } from 'react';
import { Box, Grid, Switch, Typography } from '@mui/material';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import { OrderActivityItem } from './components/OrderActivityItem';
import currencyFormatter from 'currency-formatter';
import { MetatraderPosition } from 'metaapi.cloud-sdk';
import { useMetaCloudHook } from '../meta-api-cloud/meta-api-cloud-hooks';
import { sortPosition } from './order-utils';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeActivityViewStore } from './order-stores';
import { changeActivityViewStoreAction } from './order-actions';
import { ORDER_ACTIVITY_SHORT_COLUMNS, ORDER_ACTIVITY_LONG_COLUMNS } from './order-constants';

export const OrderActivity: React.FC = () => {
  const [positions, setPositions] = useState<MetatraderPosition[]>([]);
  const { short } = useStore(changeActivityViewStore);

  const { connection } = useMetaCloudHook();
  const terminalState = connection?.terminalState;
  const accountInformation = terminalState?.accountInformation;

  useEffect(() => {
    if (connection) {
      setInterval(() => {
        setPositions(
          sortPosition(
            connection.terminalState.positions.filter(
              (position) => position.openPrice && position.currentPrice,
            ),
          ),
        );
      }, 500);
    }
  }, [connection]);

  const balance = currencyFormatter.format(
    accountInformation?.balance as number,
    { code: accountInformation?.currency },
  );

  const totalProfit = positions.reduce((acc, curr) => {
    return acc + curr.profit;
  }, 0);

  const columns = short ? ORDER_ACTIVITY_SHORT_COLUMNS : ORDER_ACTIVITY_LONG_COLUMNS;

  return (
    <Box>
      <Box display={'flex'} alignItems='center' justifyContent='space-between'>
        <Box display={'flex'} alignItems='center'>
          <CallMissedOutgoingIcon fontSize='medium' />
          <Typography fontWeight={600} paddingLeft={'10px'}>
            Order Activity
          </Typography>
        </Box>

        <Box display='flex' alignItems='center' sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Typography fontWeight={600}>
            Long
          </Typography>
          <Switch
            size='medium'
            style={{ color: '#55BE2D' }}
            checked={short}
            onClick={() => changeActivityViewStoreAction(!short)
            }
          />
          <Typography fontWeight={600}>
            Short
          </Typography>
        </Box>

      </Box>

      <Box style={{ overflow: 'auto' }}>
        <Box
          my={'15px'}
          border={'1px solid #F1F1F1'}
          borderRadius={'10px'}
          style={{ background: 'white', minWidth: short ? '100%' : 800 }}
        >
          <Box
            px={'25px'}
            py={'6px'}
            style={{ background: totalProfit > 0 ? '#196EF4' : '#E04029' }}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems='center'
          >
            <Box color={'white'} sx={{ fontSize: { xs: 13, md: 16 }, display: { xs: 'block', md: 'flex' }, width: { md:'15%'} }}>
              <Box>Balance:</Box>
              <Box fontWeight={600}>{balance}</Box>
            </Box>
            <Box fontWeight='bolder' color={'white'}
                 textAlign='center'
                 sx={{ fontSize: { xs: 16, md: 20 }, marginLeft: { xs: '8%', md: 0 }, width: { md:'70%'} }}>
              {(totalProfit || 0).toFixed(2)}
            </Box>
            <Box color={'white'} sx={{ fontSize: { xs: 13, md: 16 }, display: { xs: 'block', md: 'flex' }, width: { md:'15%'} }} justifyContent='end'>
              <Box>Margin level (%):</Box>
              <Box fontWeight={600}>
                {(accountInformation?.marginLevel || 0).toFixed(2)}
              </Box>
            </Box>
          </Box>
          <Grid my={'10px'} px={'10px'} container>
            <Grid
              container
              py={'5px'}
              style={{ background: '#F8F8F8' }}
              borderBottom={'1px solid #F0F0F0'}
              px={'15px'}
            >
              {
                columns.map((column) => (
                  <Grid item xs={column.xs} textAlign='center'>
                    <Typography fontWeight={600}>{column.name}</Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          {positions.map((position, key) => (
            <OrderActivityItem position={position} key={key} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
