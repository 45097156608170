import { useContext, useEffect, useCallback, useState } from 'react';
import { Context, DefaultValuesContextType } from './MetaApiCloudContext';
import {
  metaStatsMetrics,
  metaStatsOpenTrades,
} from './meta-api-cloud-actions';
import { useEvent } from '@cobuildlab/react-simple-state';
import { fetchUserEvent } from '../auth/auth-events';
import {
  MetaStatMetric,
  MetaStatTrade,
  MetaStatTradeDealType,
} from './meta-api-cloud-types';

export const useMetaCloudHook = (): DefaultValuesContextType => {
  const { isConnectedToBroker, connection, accountId } = useContext(Context);
  return {
    connection,
    isConnectedToBroker,
    accountId,
  };
};

export const useMetaStatTrades = (): {
  trades: MetaStatTrade[];
  loading: boolean;
} => {
  const [trades, setTrades] = useState<MetaStatTrade[]>([]);
  const [accountId, setAccountId] = useState<string | undefined>(undefined);
  const { user } = useEvent(fetchUserEvent);
  const [loading, setLoading] = useState(true);

  const fetchTrades = useCallback(async () => {
    const openTrades = await metaStatsOpenTrades();
    setTrades(
      openTrades
        .filter(
          (trade) =>
            trade.type === MetaStatTradeDealType.DealTypeBuy ||
            trade.type === MetaStatTradeDealType.DealTypeSell,
        )
        .sort(function (a, b) {
          return (
            new Date(b?.closeTime as string).getTime() -
            new Date(a?.closeTime as string).getTime()
          );
        }),
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      const metaApiCloudAccountId = user.MetaApiCloudAccount
        ? user.MetaApiCloudAccount.accountId
        : undefined;
      setAccountId(metaApiCloudAccountId);
    }
  }, [user]);

  useEffect(() => {
    if (accountId) {
      fetchTrades();
      setInterval(() => {
        fetchTrades();
      }, 60000);
    }
  }, [accountId, fetchTrades]);

  return { trades, loading };
};

export const useMetaStatMetrics = (): {
  metrics: MetaStatMetric | undefined;
  loading: boolean;
  refetch: () => void;
} => {
  const [metrics, setMetrics] = useState<MetaStatMetric | undefined>(undefined);
  const [accountId, setAccountId] = useState<string | undefined>(undefined);
  const { user } = useEvent(fetchUserEvent);
  const [loading, setLoading] = useState(true);

  const fetchMetrics = useCallback(async () => {
    const metricsData = await metaStatsMetrics(accountId);
    setMetrics(metricsData);
    setLoading(false);
  }, [accountId]);

  useEffect(() => {
    if (user) {
      const metaApiCloudAccountId = user.MetaApiCloudAccount
        ? user.MetaApiCloudAccount.accountId
        : undefined;
      setAccountId(metaApiCloudAccountId);
    }
  }, [user]);
  useEffect(() => {
    if (accountId) {
      fetchMetrics();
    }
  }, [accountId, fetchMetrics]);

  const refetch = (): void => {
    fetchMetrics();
  };

  return { metrics, loading, refetch };
};
