import { createEvent } from '@cobuildlab/react-simple-state';
import { User } from '../auth/auth-types';

export const updateAuth0UserEvent = createEvent<{ user: User | undefined }>({
  initialValue: {
    user: undefined,
  },
});

export const updateAuth0UserErrorEvent = createEvent<Error>();
