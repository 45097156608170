import React, { ReactElement, useEffect, useState } from 'react';
import { useEvent } from '@cobuildlab/react-simple-state';
import { fetchUserEvent } from '../auth/auth-events';
import { UserRole } from '../user/user-types';
import { checkUserRole } from '../user/user-utils';

export const ErrorDefaultView: React.FC = () => (
  <div>You dont have permission</div>
);

type RoleAuthorizationProps = {
  render: () => ReactElement<string | React.JSXElementConstructor<unknown>>; // eslint-disable-line @typescript-eslint/no-explicit-any
  error?: () => ReactElement<
    string | React.JSXElementConstructor<unknown>
  > | null; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const RoleAuthorizationAdmin: React.FC<RoleAuthorizationProps> = ({
  render,
  error = () => <ErrorDefaultView />,
}) => {
  const { user } = useEvent(fetchUserEvent);
  const [checkAuth, setCheckAuth] = useState<boolean>(
    checkUserRole(UserRole.ADMIN),
  );
  useEffect(() => {
    setCheckAuth(checkUserRole(UserRole.ADMIN));
  }, [user]);

  if (checkAuth) return render();
  return error();
};
