import React, { useState } from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import { PairType } from '../pair-types';
import {
  createStrategyConfiguration,
  deleteStrategyConfiguration,
} from '../../strategy/strategy-actions';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchUserEvent } from '../../auth/auth-events';
import { StrategyConfiguration } from '../../strategy/strategy-types';

type PairItemProps = {
  pair: PairType;
  onSuccess: () => void;
};

export const PairItem: React.FC<PairItemProps> = ({ pair, onSuccess }) => {
  const [strategyConfiguration, setStrategyConfiguration] = useState<
    StrategyConfiguration | undefined
  >(
    pair?.strategyConfiguration?.length
      ? pair?.strategyConfiguration[0]
      : undefined,
  );
  const { user } = useEvent(fetchUserEvent);

  const [callCreateStrategyConfiguration] = useCallAction(
    createStrategyConfiguration,
    {
      onCompleted: (data) => {
        if (data.strategyConfiguration.strategyId === pair.strategyId) {
          onSuccess();

          setStrategyConfiguration(data.strategyConfiguration);

        }

        openSnackbarAction('Update pair successfully!', TypeMessage.SUCCESS);
      },
      onError: () => {
        openSnackbarAction('Error to update account', TypeMessage.ERROR);
      },
    },
  );

  const [callDeleteStrategyConfiguration] = useCallAction(
    deleteStrategyConfiguration,
    {
      onCompleted: (data) => {

        if (data.strategyConfiguration.strategyId === pair.strategyId) {
          onSuccess();

          setStrategyConfiguration(undefined);

        }

        openSnackbarAction('Update pair successfully!', TypeMessage.SUCCESS);
      },
      onError: () => {
        openSnackbarAction('Error to update account', TypeMessage.ERROR);
      },
    },
  );

  return (
    <Grid my={'10px'} px={'10px'} container>
      <Grid container py={'5px'} borderBottom={'1px solid #F0F0F0'}>
        <Grid item xs={3}>
          <Typography fontWeight={600}>{pair.symbol}</Typography>
        </Grid>
        <Grid style={{ color: '#55BE2D' }} item xs={6}>
          <Typography textAlign={'center'}>
            {pair.price ? pair.price?.bid : 0}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'center'}>
          <Switch
            size='medium'
            style={{ color: '#55BE2D' }}
            checked={!!strategyConfiguration}
            onClick={() =>
              strategyConfiguration
                ? callDeleteStrategyConfiguration({
                  id: strategyConfiguration?.id,
                })
                : callCreateStrategyConfiguration({
                  userId: user?.id,
                  strategyId: pair?.strategyId,
                })
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
