import { createStoreAction } from '@cobuildlab/react-simple-state';
import { changeStepStore } from './onboarding-events';

export const changeStep = createStoreAction(
  changeStepStore,
  (prev, step: number) => ({
    ...prev,
    step,
  }),
);
