import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RiskSelector } from '../risk/Risk';
import { CreateAccount } from './components/create-account/CreateAccount';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import { changeStepStore } from './onboarding-events';
import { changeStep } from './onboarding-actions';
import { Account } from './components/account/Account';
import { fetchRisks } from '../risk/risk-actions';
import { Loading } from '../../shared/components/Loading';
import { useNavigate } from 'react-router-dom';
import { fetchUserEvent } from '../auth/auth-events';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',
      height: 30,
      width: 30,
      margin: '0 0',
    },
    eventsNone: {
      pointerEvents: 'none',
    },
  }),
);

const STEPS = 3;
export const Onboarding: React.FC = () => {
  const classes = useStyles();
  const { step } = useStore(changeStepStore);
  const [loading, setLoading] = useState(true);
  const [callRisks] = useCallAction(fetchRisks, {
    onCompleted: () => setLoading(false),
  });
  const { user } = useEvent(fetchUserEvent);

  const navigate = useNavigate();

  const isCompletedOnboarding =
    user &&
    user.meta.find(
      (_meta) => _meta.key === 'is_completed' && _meta.value === '1',
    );

  useEffect(() => {
    if (isCompletedOnboarding) return () => navigate('/');
    if (loading) callRisks();
  }, [callRisks, loading, isCompletedOnboarding, navigate]);

  let content = <></>;

  if (loading) return <Loading />;

  switch (step) {
    case 1:
      content = <CreateAccount />;
      break;
    case 2:
      content = <Account />;
      break;
    case 3:
      content = <RiskSelector />;
      break;
  }
  return (
    <div>
      <Box
        position={'relative'}
        height={'80px'}
        paddingX={'10px'}
        display={'flex'}
        justifyContent={'justify-between'}
        alignItems={'center'}
      >
        <Box
          className={classes.eventsNone}
          width={'100%'}
          position={'absolute'}
          top={0}
          left={'-2px'}
          height={'80px'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography fontWeight={600}>Account Setup</Typography>
            <Typography fontWeight={600} fontSize={14}>
              Step {step + '/' + STEPS}
            </Typography>
          </Box>
        </Box>
        <button
          className={classes.cleanButton}
          onClick={() => {
            if (step > 1) {
              changeStep(step - 1);
            }
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </button>
      </Box>
      <LinearProgress
        sx={{
          height: 8,
          borderRadius: 5,

          backgroundColor: 'white',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#44DD29',
            borderRadius: 5,
          },
        }}
        variant="determinate"
        value={(100 / STEPS) * step}
      />
      <Box>{content}</Box>
    </div>
  );
};
