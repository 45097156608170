import React from 'react';
import {
  DeploymentPhase,
  DigitaloceanDeployment,
} from '../../digitalocean/digitalocean-types';
import { Box, Chip, Grid } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckIcon from '@mui/icons-material/Check';
import DangerousIcon from '@mui/icons-material/Dangerous';
import moment from 'moment/moment';

type SettingBuildItemProps = {
  deployment: DigitaloceanDeployment;
};
export const SettingBuildItem: React.FC<SettingBuildItemProps> = ({
                                                                    deployment,
                                                                  }) => {

  if (!deployment) return null;

  const name = deployment.cause_details?.digitalocean_user_action
    ? deployment.cause_details.digitalocean_user_action.user.full_name
    : deployment.cause_details?.git_push?.username;

  let content = <></>;
  let text = '';

  switch (deployment.phase) {
    case DeploymentPhase.active:
      content = (
        <Chip
          label='LIVE'
          color='success'
          sx={{
            '&': {
              borderRadius: 1,
            },
          }}
        />
      );
      text = 'deployment went live';
      break;
    case DeploymentPhase.canceled:
      content = <CancelRoundedIcon color='disabled' />;
      text = 'canceled a deployment';
      break;
    case DeploymentPhase.superseded:
      content = <CheckIcon color='success' />;
      text = 'deployment went live';
      break;
    case DeploymentPhase.error:
      content = <DangerousIcon color='error' />;
      text = 'deployment failed during build phase';
      break;
    default:
      content = <AutorenewIcon />;

      text = 'deployment is currently in build phase (restarting the server)';
      break;
  }

  console.log({ deployment });

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={2}
        md={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        {content}
      </Grid>
      <Grid item xs={10} md={11} display='flex' alignItems='center'>
        <Box component='span' mr={0.5} fontWeight='bold'>
          {name}
        </Box>
        <Box component='span'>{text} {moment(deployment.created_at).format('LLL')}</Box>
      </Grid>
    </Grid>
  );
};
