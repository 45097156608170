import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';
import { theme } from './shared/css/theme';
import { Routes } from './routes';
import { CssBaseline } from '@mui/material';
import './App.css';
import { Snackbar } from './shared/components/snackbar/Snackbar';

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Snackbar />
        <Routes />
      </ThemeProvider>
    </BrowserRouter>
  );
};
