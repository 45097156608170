import { createAction } from '@cobuildlab/react-simple-state';
import { fetchMetricsErrorEvent, fetchMetricsEvent } from './metric-events';
import axios from 'axios';
import { BACKEND_URL } from '../../shared/contants';
import { fetchUserEvent, OnTokenEvent } from '../auth/auth-events';

export const fetchMetrics = createAction(fetchMetricsEvent, fetchMetricsErrorEvent, async () => {
  const token = OnTokenEvent.get()?.token;
  const accountId = fetchUserEvent.get()?.metaApiAccount?.accountId;


  const response = await axios.get(`${BACKEND_URL}/metrics?account_id=${accountId}`, {
    params: {},
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
});