import React from 'react';
import { Grid } from '@mui/material';
import { CreateAccountForm } from './CreateAccountForm';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { updateMeta } from '../../../meta/meta-actions';
import { changeStep } from '../../onboarding-actions';
import { fetchUserEvent } from '../../../auth/auth-events';
import { fetchCurrentUser } from '../../../auth/auth-actions';

export const Account: React.FC = () => {
  const { user } = useEvent(fetchUserEvent);

  const [callUpdateMeta, loadingMeta] = useCallAction(updateMeta, {
    onCompleted: () => {
      changeStep(3);
    },
  });

  const [callCurrentUser, isLoading] = useCallAction(fetchCurrentUser, {
    onCompleted: () => {
      const meta = user?.meta.find((_meta) => _meta.key === 'on_boarding_step');
      if (meta)
        callUpdateMeta({ id: meta.id, key: 'on_boarding_step', value: '3' });
    },
  });

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={3}>
        <CreateAccountForm
          successLoading={isLoading || loadingMeta}
          onSuccess={() => {
            callCurrentUser();
          }}
        />
      </Grid>
    </Grid>
  );
};
