import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchUsers } from './user-actions';
import { User } from '../auth/auth-types';
import { UserItem } from './components/UserItem';
import { Loading } from '../../shared/components/Loading';

export const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [callAction, loading] = useCallAction(fetchUsers, {
    onCompleted: (data) => {
      setUsers(data.users);
    },
  });
  useEffect(() => {
    callAction();
  }, [callAction]);

  return (
    <Box>
      <Box display={'flex'}>
        <GroupIcon fontSize="medium" />
        <Typography fontWeight={600} paddingLeft={'10px'}>
          Users
        </Typography>
      </Box>
      <Box style={{ overflow: 'auto' }}>
        <Box
          my={'15px'}
          border={'1px solid #F1F1F1'}
          borderRadius={'10px'}
          style={{ background: 'white', minWidth: 850 }}
        >
          <Grid py={'5px'} px={'15px'} container>
            <Grid
              container
              py={'5px'}
              style={{ background: '#F8F8F8' }}
              borderBottom={'1px solid #F0F0F0'}
              px={'15px'}
            >
              <Grid item xs={3}>
                <Typography fontWeight={600}>Name</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontWeight={600} textAlign={'center'}>
                  Email
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontWeight={600} textAlign={'center'}>
                  Role
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontWeight={600} textAlign={'center'}>
                  Created at
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {loading ? (
            <Loading height="350px" />
          ) : (
            users.map((user) => <UserItem user={user} />)
          )}
        </Box>
      </Box>
    </Box>
  );
};
