import React from 'react';
import {
  Theme,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import HomeIcon from '@mui/icons-material/Home';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import GroupIcon from '@mui/icons-material/Group';

import { useNavigate } from 'react-router-dom';
import { useStore } from '@cobuildlab/react-simple-state';
import { drawerSidebarEvent } from './layout-events';
import { DRAWER_WIDTH, MIN_DRAWER_WIDTH } from '../../contants';
import { CSSObject, styled } from '@mui/material/styles';

type StylesProps = {
  drawerWidth: number;
  minDrawerWidth: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints?.up('sm')]: {
        /**
         *
         * @param {object} props - Styles Props.
         * @param {boolean} props.drawerWidth - Drawer Width.
         * @returns {number} - Drawer Width.
         */
        flexShrink: 0,
        transition: theme.transitions.create(['width', 'transform'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    drawerPaper: {
      /**
       *
       * @param {object} props - Styles Props.
       * @param {boolean} props.drawerWidth - Drawer Width.
       * @returns {number} - Drawer Width.
       */
      alignItems: 'center',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    listItem: {
      height: 56,
      borderRadius: 4,
      paddingLeft: 20,
      display: 'flex',
      marginBottom: theme.spacing(1),
      '& > .MuiListItemText-root > .MuiTypography-root': {
        fontWeight: '700!important',
      },
      '&.Mui-selected': {
        color: '#808080',
        backgroundColor: 'rgba(128, 128, 128, 0.64)',
        '& .MuiListItemIcon-root': {
          color: '#808080',
        },
      },
      '&:hover': {
        '&.Mui-selected': {
          color: '#808080',
          backgroundColor: 'rgba(128, 128, 128, 0.64)',
          '& .MuiListItemIcon-root': {
            color: '#808080',
          },
        },
      },
    },
  }),
);

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: MIN_DRAWER_WIDTH,
  [theme.breakpoints.up('sm')]: {
    width: MIN_DRAWER_WIDTH,
  },
});

const ROUTES = [
  {
    path: '/',
    name: 'Dashboard',
    icon: <HomeIcon fontSize='medium' />,
  },
  {
    path: '/order-activity',
    name: 'Activity',
    icon: <CallMissedOutgoingIcon fontSize='medium' />,
  },
  {
    path: '/order-history',
    name: 'History',
    icon: <AssessmentOutlinedIcon fontSize='medium' />,
  },
  {
    path: '/users',
    name: 'Users',
    icon: <GroupIcon fontSize='medium' />,
  },
  {
    path: '/setting/account',
    name: 'Settings',
    icon: <SettingsIcon fontSize='medium' />,
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: <LogoutIcon fontSize='medium' />,
  },
];

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const SideMenu: React.FC = () => {
  const { isOpen } = useStore(drawerSidebarEvent);
  const navigate = useNavigate();
  const classes = useStyles({
    drawerWidth: DRAWER_WIDTH,
    minDrawerWidth: MIN_DRAWER_WIDTH,
  });

  return (
    <Drawer
      sx={{ display: { md: 'block', xs: 'none' } }}
      variant='permanent'
      open={isOpen}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {isOpen ? (
        <img
          src={'/ihuw-logo.svg'}
          alt={'logo'}
          width={'100px'}
          height={'50px'}
          style={{ objectFit: 'contain', marginTop: 15 }}
        />
      ) : (
        <img
          src={'/ihuw_symbol.png'}
          alt={'logo'}
          width={'100px'}
          height={'50px'}
          style={{ objectFit: 'contain', marginTop: 10, textAlign: 'center' }}
        />
      )}

      <Box
        width='100%'
        height='100%'
        display='flex'
        justifyContent='space-between'
        flexDirection='column'
      >
        <List style={{ width: '100%' }}>
          {ROUTES.map((route, index) => (
            <ListItem
              className={classes.listItem}
              button
              key={index}
              disablePadding
              sx={{ display: 'block' }}
              onClick={() => navigate(route.path)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 30,
                  mr: 0,
                  justifyContent: 'center',
                }}
              >
                {route.icon}
              </ListItemIcon>
              <ListItemText
                primary={route.name}
                sx={{ opacity: isOpen ? 1 : 0 }}
              />
            </ListItem>
          ))}
        </List>
        <Box textAlign='center'>
          <Tooltip title='Add a pair'>
            <IconButton
              aria-label='Add a pair'
              onClick={() => navigate('/pairs')}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Drawer>
  );
};
