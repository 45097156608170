import { createEvent } from '@cobuildlab/react-simple-state';
import { Risk } from './risk-types';

export const fetchRisksEvent = createEvent<{ risks: Risk[] }>({
  initialValue: {
    risks: [],
  },
});

export const fetchRisksErrorEvent = createEvent<Error>();
