import React from 'react';
import {
  AppBar,
  // Avatar,
  Box,
  // Chip,
  // Grid,
  IconButton,
  Theme,
  Toolbar,
} from '@mui/material';
import { useStore } from '@cobuildlab/react-simple-state';
import { drawerSidebarEvent } from './layout-events';
import { createStyles, makeStyles } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DRAWER_WIDTH, MIN_DRAWER_WIDTH } from '../../contants';
import { openDrawerSidebar } from './layout-actions';

import { DrawerMenu } from './DrawerMenu';
import { Time } from './Time';
// import { TradingVolumen } from './TradingVolumen';
import { ConnectionBroker } from './ConnectionBroker';

interface StylesProps {
  drawerWidth: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: 'white!important',
      color: 'black',
      boxShadow: 'none!important',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      height:70
    },
    toolbar: {
      width: '100%',
      alignItems:'center'
    },
    countryBadge: {
      backgroundColor: '#d7ffd2',
      color: '#207d32',
      fontWeight: 'bold',
      '& > .MuiChip-label ': {
        textOverflow: 'initial',
      },
    },
    tradingBadge: {
      fontWeight: 'bold',
      '& > .MuiChip-label ': {
        textOverflow: 'initial',
        overflow: 'initial',
      },
    },
    appBarShift: {
      /**
       *
       * @param {object} props - Styles Props.
       * @param {number} props.drawerWidth - DrawerWidth.
       * @returns {string} - Width Style.
       */
      /**
       *
       * @param {object} props - Styles Props.
       * @param {boolean} props.drawerWidth - Drawer Width.
       * @returns {number} - Drawer Width.
       */
      marginLeft: (props) => props.drawerWidth,
      backgroundColor: '#F7F7F7',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
);

export const TopMenu: React.FC = () => {
  const { isOpen } = useStore(drawerSidebarEvent);
  const classes = useStyles({
    drawerWidth: 250,
  });

  return (
    <AppBar
      sx={{
        width: {
          md: `calc(100% - ${isOpen ? DRAWER_WIDTH : MIN_DRAWER_WIDTH}px)`,
          xs: '100%',
        },
      }}
      position="fixed"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          onClick={() => openDrawerSidebar(!isOpen)}
          sx={{ display: { md: 'flex', xs: 'none' } }}
        >
          {!isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
          <DrawerMenu />
        </Box>
        <Box
          display={'flex'}
          height={'80px'}
          justifyContent={'space-between'}
          width="100%"
          sx={{
            padding: { md: '0 15px', xs: '0' },
          }}
        >
          <Time />
          <Box sx={{ display: { md: 'none', xs: 'flex' } }} alignItems="center" mr='15%'>
            <img
              src={'/ihuw-logo.svg'}
              alt={'logo'}
              width={'100px'}
              height={'50px'}

              style={{ objectFit: 'contain' }}
            />
          </Box>
          {/*<TradingVolumen />*/}
          <ConnectionBroker />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
