import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchRisksEvent } from './risk-events';
import { Risk } from './risk-types';
import { RiskItem } from './components/RiskItem';
import { createUserRisk } from '../user/user-actions';
import { updateMeta } from '../meta/meta-actions';
import { fetchUserEvent } from '../auth/auth-events';
import { fetchCurrentUser } from '../auth/auth-actions';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#44DD29!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#44DD29!important',
  },
  height: 40,
  width: 180,
});

export const RiskSelector: React.FC = () => {
  const [selectedRisk, setSelectedRisk] = useState<Risk | undefined>(undefined);
  const { risks } = useEvent(fetchRisksEvent);
  const { user } = useEvent(fetchUserEvent);
  const navigate = useNavigate();
  const [callCurrentUser] = useCallAction(fetchCurrentUser, {
    onCompleted: () => navigate('/'),
  });

  const [callUpdateMeta] = useCallAction(updateMeta, {
    onCompleted: () => {
      callCurrentUser();
    },
  });

  const [callCreateUserRisk, loading] = useCallAction(createUserRisk, {
    onCompleted: () => {
      const meta = user?.meta.find((_meta) => _meta.key === 'is_completed');
      if (meta)
        callUpdateMeta({ id: meta.id, key: 'is_completed', value: '1' });
    },
  });

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      px={'10px'}
    >
      <Grid item sm={10} md={8} lg={6} marginTop={'50px'}>
        <Grid container spacing={2}>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Typography fontWeight={700} fontSize={18} textAlign={'center'}>
              Choose your risk profile
            </Typography>
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Typography fontSize={12} textAlign={'center'}>
              Select your risk based on your account capital
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              paddingTop={'20px'}
              container
              columnSpacing={2}
              rowSpacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {risks.map((risk) => (
                <Grid item xs={6} sm={4}>
                  <RiskItem
                    risk={risk}
                    onSelected={(selected) => setSelectedRisk(selected)}
                    selected={selectedRisk}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              marginTop={'50px'}
              display={'flex'}
              justifyContent={'center'}
            >
              <StyledButton
                variant="contained"
                color="secondary"
                isLoading={loading}
                onClick={() => callCreateUserRisk(selectedRisk as Risk)}
              >
                Continue
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
