import { createAction } from '@cobuildlab/react-simple-state';
import {
  digitaloceanDeploymentsErrorEvent,
  digitaloceanDeploymentsEvent,
  digitaloceanResetServerErrorEvent,
  digitaloceanResetServerEvent,
} from './digitalocean-events';
import axios from 'axios';
import {
  DigitaloceanDeployment,
  DigitaloceanDeployments,
} from './digitalocean-types';
import { DIGITALOCEAN_TOKEN } from '../../shared/contants';

export const digitaloceanResetServer = createAction(
  digitaloceanResetServerEvent,
  digitaloceanResetServerErrorEvent,
  async () => {
    const response = await axios.post<{ deployment?: DigitaloceanDeployment }>(
      'https://api.digitalocean.com/v2/apps/d1b2dbc4-a018-4dae-9c93-449bfaa49448/deployments',
      {
        force_build: true,
      },
      {
        headers: {
          authorization: `Bearer ${DIGITALOCEAN_TOKEN}`,
        },
      },
    );

    return response.data;
  },
);

export const digitaloceanDeployments = createAction(
  digitaloceanDeploymentsEvent,
  digitaloceanDeploymentsErrorEvent,
  async () => {
    const response = await axios.get<DigitaloceanDeployments>(
      'https://api.digitalocean.com/v2/apps/d1b2dbc4-a018-4dae-9c93-449bfaa49448/deployments?page=1&per_page=10',
      {
        headers: {
          authorization: `Bearer ${DIGITALOCEAN_TOKEN}`,
        },
      },
    );

    return response.data;
  },
);
