import { createStoreAction } from '@cobuildlab/react-simple-state';
import { changeActivityViewStore, changeOrderHistoryViewStore } from './order-stores';


export const changeActivityViewStoreAction = createStoreAction(changeActivityViewStore, (prev, short: boolean) => ({
  ...prev,
  short,
}));


export const changeOrderHistoryViewStoreAction = createStoreAction(changeOrderHistoryViewStore, (prev, short: boolean) => ({
  ...prev,
  short,
}));