import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import { Dropdown1 } from '../../shared/components/utils/Dropdown1';
import { PairItem } from './components/PairItem';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchStrategyPairsEvent } from '../strategy/strategy-events';
import { useMetaCloudHook } from '../meta-api-cloud/meta-api-cloud-hooks';
import { PairType } from './pair-types';
import { sortPairs } from './pair-utils';
import { fetchStrategyPairs } from '../strategy/strategy-actions';
import { Loading } from '../../shared/components/Loading';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',
      height: 30,
      width: 30,
      margin: '0 0',
    },
    eventsNone: {
      pointerEvents: 'none',
    },
  }),
);

const optionTime = ['1M', '1S', '1H'];
const optionPriceStrem = ['Real Time', 'Time lapse', 'Historic'];
export const Pairs: React.FC = () => {
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [actualTimeOption, setActualTimeOption] = useState(0);
  const [actualOptionPriceStrem, setActualOptionPriceStrem] = useState(0);
  const classes = useStyles();
  const { strategies } = useEvent(fetchStrategyPairsEvent);
  const [pairs, setPairs] = useState<PairType[]>([]);
  const { connection } = useMetaCloudHook();
  const [loading, setLoading] = useState(true);
  const [callStrategyPairs] = useCallAction(fetchStrategyPairs, {
    onCompleted: () => setLoading(false),
  });

  useEffect(() => {
    callStrategyPairs();
  }, [callStrategyPairs]);

  useEffect(() => {
    if (connection && strategies.length) {
      setInterval(() => {
        setPairs(
          sortPairs(
            strategies.map((strategy) => {
              return {
                symbol: strategy.symbol,
                strategyId: strategy.id,
                strategyConfiguration: strategy.strategyConfiguration,
                price: connection?.terminalState.price(strategy.symbol),
              };
            }),
          ),
        );
      }, 1000);
    }
  }, [connection, strategies, strategies.length]);
  console.log({ pairs });
  return (
    <Box
      border={'1px solid #F1F1F1'}
      borderRadius={'10px'}
      style={{ background: 'white' }}
    >
      <Grid my={'10px'} px={'10px'} container>
        <Grid
          container
          py={'5px'}
          style={{ background: '#F8F8F8' }}
          borderBottom={'1px solid #F0F0F0'}
          px={'15px'}
        >
          <Grid display={'flex'} alignItems={'center'} item xs={3}>
            <Typography fontWeight={600}>Pair</Typography>
            <Box
              position={'relative'}
              display={'flex'}
              alignItems={'center'}
              width={40}
            >
              <button
                onClick={() => setOpenFilterMenu(!openFilterMenu)}
                className={classes.cleanButton}
              >
                <SettingsIcon fontSize="small" />
              </button>
              {openFilterMenu ? (
                <Box paddingBottom={'3px'} width={400}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography width={140}>Time Interval</Typography>
                    <Box paddingLeft={1}>
                      <Dropdown1
                        options={optionTime}
                        selectedIndex={actualTimeOption}
                        setSelectedIndex={setActualTimeOption}
                      />
                    </Box>
                    <Typography paddingLeft={1} width={150}>
                      Price Stream
                    </Typography>
                    <Box paddingLeft={1} width={200}>
                      <Dropdown1
                        options={optionPriceStrem}
                        selectedIndex={actualOptionPriceStrem}
                        setSelectedIndex={setActualOptionPriceStrem}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={600} textAlign={'center'}>
              Last Price
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} textAlign={'center'}>
              Actions
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <Loading height="350px" />
        ) : (
          pairs.map((pair) => (
            <PairItem pair={pair} onSuccess={() => callStrategyPairs()} />
          ))
        )}
      </Grid>
    </Box>
  );
};
