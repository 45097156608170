import { createEvent } from '@cobuildlab/react-simple-state';
import { Meta } from './meta-types';

export const updateMetaEvent = createEvent<{ meta: Meta | undefined }>({
  initialValue: {
    meta: undefined,
  },
});

export const updateMetaErrorEvent = createEvent<Error>();
