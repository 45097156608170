import { UserRole } from './user-types';
import { fetchUserEvent } from '../auth/auth-events';

export const checkUserRole = (role: UserRole): boolean => {
  const user = fetchUserEvent.get()?.user;
  if (user && user.role) {
    return user.role.name === role;
  }

  return false;
};
