

export enum OrderField  {
  Pair = 'pair',
  Position = 'price',
  Amount = 'amount',
  Profit = 'profit',
  Action = 'action',
  Date = 'date',
  Pips = 'pips',
}