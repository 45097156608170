import { createStore } from '@cobuildlab/react-simple-state';


export const changeActivityViewStore = createStore({
  initialValue: {
    short: false,
  },
});

export const changeOrderHistoryViewStore = createStore({
  initialValue: {
    short: false,
  },
});