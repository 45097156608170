import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import { DashboardCardItem } from './components/DashboardCardItem';
import currencyFormatter from 'currency-formatter';
import { useMetaCloudHook } from '../meta-api-cloud/meta-api-cloud-hooks';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchMetrics } from '../metric/metric-actions';
import { fetchMetricsEvent } from '../metric/metric-events';

type DashboardProps = {
  number?: number;
};

export const Dashboard: React.FC<DashboardProps> = () => {
  const { connection } = useMetaCloudHook();
  const terminalState = connection?.terminalState;
  const [loading, setLoading] = useState(true);

  const {
    trade: {
      tradesCloseInLose,
      tradesCloseInProfit,
      bestPair,
    },
    metrics
  } = useEvent(fetchMetricsEvent);
  // const accountInformation = terminalState?.accountInformation;
  const balance = currencyFormatter.format(metrics?.balance as number, {
    code: terminalState?.accountInformation?.currency,
  });

  const periods = metrics?.periods;
  const today = periods?.today;
  const todayProfit = currencyFormatter.format(today?.profit as number, {
    code: terminalState?.accountInformation?.currency,
  });


  const [callMetrics] = useCallAction(fetchMetrics, {
    onError: (error) => console.log({ error }),
    onCompleted: (data) => {
      console.log({ data });
      setLoading(false);
    },
  });

  useEffect(() => {
    callMetrics();
    setInterval(() => {
      callMetrics();

    }, 60000);
  }, [callMetrics]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center'>
          <HomeIcon fontSize='medium' /> Dashboard
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography>Here are some insights on your account</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <DashboardCardItem
              title='ACCOUNT BALANCE'
              content={balance}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCardItem
              title='TODAY CURRENT PROFIT'
              content={todayProfit}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCardItem
              title='ACCOUNT MARGIN LEVEL (%)'
              content={metrics?.marginLevel || 0}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCardItem
              title='CURRENT OPEN TRADES'
              content={connection?.terminalState.positions.length || 0}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCardItem
              title='TODAY MOST PROFITABLE PAIR'
              content={bestPair.currency}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCardItem
              title='TODAY TOTAL TRADES'
              content={tradesCloseInProfit + tradesCloseInLose}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCardItem
              title='TODAY TRADES CLOSED IN PROFIT'
              loading={loading}
              content={tradesCloseInProfit}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCardItem
              title='TODAY TRADES CLOSED IN LOSS'
              loading={loading}
              content={tradesCloseInLose}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
