import { createEvent } from '@cobuildlab/react-simple-state';
import { User } from './auth-types';
import { UserMetaApiAccount } from '../user/user-types';

export interface InitialStateType {
  token: null | string;
}

const INITIAL_STATE = {
  token: null,
};

export const OnTokenEvent = createEvent<InitialStateType>({
  initialValue: INITIAL_STATE,
});
export const OnTokenErrorEvent = createEvent<Error>();

export const fetchUserEvent = createEvent<{ user?: User, metaApiAccount?: UserMetaApiAccount }>({
  initialValue: { user: undefined },
  reducer: (prevState) => {
    const user = prevState.user;
    const metaApiAccount = prevState.user?.MetaApiCloudAccount;
    return { user, metaApiAccount };
  },
});

export const fetchUserErrorEvent = createEvent<Error>();
