import React, { useEffect, useState } from 'react';
import { Box, Grid, Switch, Typography } from '@mui/material';
import {
  useMetaCloudHook,
  useMetaStatTrades,
} from '../meta-api-cloud/meta-api-cloud-hooks';
import currencyFormatter from 'currency-formatter';
import { OrderHistoryItem } from './components/OrderHistoryItem';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Loading } from '../../shared/components/Loading';
import {
  ORDER_HISTORY_LONG_COLUMNS,
  ORDER_HISTORY_SHORT_COLUMNS,
} from './order-constants';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeOrderHistoryViewStore } from './order-stores';
import { changeOrderHistoryViewStoreAction } from './order-actions';

export const OrderHistory: React.FC = () => {
  const { short } = useStore(changeOrderHistoryViewStore);
  const { connection } = useMetaCloudHook();
  const terminalState = connection?.terminalState;
  const accountInformation = terminalState?.accountInformation;
  const { trades, loading } = useMetaStatTrades();
  const [balance, setBalance] = useState<string>('');

  useEffect(() => {
    if (connection) {
      setInterval(() => {
        setBalance(
          currencyFormatter.format(
            accountInformation?.balance as number,
            { code: accountInformation?.currency },
          ),
        );
      }, 500);
    }
  }, [connection, accountInformation]);


  const columns = short ? ORDER_HISTORY_SHORT_COLUMNS : ORDER_HISTORY_LONG_COLUMNS;

  return (
    <Box>
      <Box display={'flex'} alignItems='center' justifyContent='space-between'>

        <Box display={'flex'} alignItems='center'>
          <AssessmentOutlinedIcon fontSize='medium' />
          <Typography fontWeight={600} paddingLeft={'10px'}>
            Order History
          </Typography>
        </Box>
        <Box display='flex' alignItems='center'
             sx={{ display: { xs: 'flex', md: 'none' } }}

        >
          <Typography fontWeight={600}>
            Long
          </Typography>
          <Switch
            size='medium'
            style={{ color: '#55BE2D' }}
            checked={short}
            onClick={() => changeOrderHistoryViewStoreAction(!short)
            }
          />
          <Typography fontWeight={600}>
            Short
          </Typography>
        </Box>

      </Box>
      <Box style={{ overflow: 'auto' }}>
        <Box
          my={'15px'}
          border={'1px solid #F1F1F1'}
          borderRadius={'10px'}
          style={{ background: 'white', minWidth: short ? '100%' : 850 }}
        >
          <Box
            px={'25px'}
            py={'6px'}
            style={{ background: '#000' }}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Box color={'white'} sx={{ fontSize: { xs: 13, md: 16 }, display: { xs: 'block', md: 'flex' } }}>
              <Box>Balance:</Box> <Box fontWeight={600}>{balance}</Box>
            </Box>

            <Box color={'white'} sx={{ fontSize: { xs: 13, md: 16 }, display: { xs: 'block', md: 'flex' } }}>
              <Box> Margin level (%):</Box>
              <Box fontWeight={600}>
                {(accountInformation?.marginLevel || 0).toFixed(2)}
              </Box>
            </Box>
          </Box>
          <Grid my={'10px'} px={'10px'} container>
            <Grid
              container
              py={'5px'}
              style={{ background: '#F8F8F8' }}
              borderBottom={'1px solid #F0F0F0'}
              px={'15px'}
            >
              {
                columns.map((column) => (
                  <Grid item xs={column.xs} textAlign='center'>
                    <Typography fontWeight={600}>{column.name}</Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>

          {loading ? (
            <Loading height='350px' />
          ) : (
            trades.map((trade) => <OrderHistoryItem trade={trade} />)
          )}
        </Box>
      </Box>
    </Box>
  );
};
