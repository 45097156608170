export enum TypeMessage {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export type SnackbarType = {
  isOpen?: boolean;
  message?: string;
  type?: TypeMessage;
};
