import React from 'react';
import { Box } from '@mui/material';
import { SymbolItem } from './SymbolItem';
import { useEvent } from '@cobuildlab/react-simple-state';
import { fetchStrategiesEvent } from '../../strategy/strategy-events';

export const SymbolList: React.FC = () => {
  const { strategies } = useEvent(fetchStrategiesEvent);
  return (
    <Box overflow="auto">
      <Box display="flex" py={1}>
        {strategies.map((strategy) => (
          <SymbolItem strategy={strategy} />
        ))}
      </Box>
    </Box>
  );
};
