import React from 'react';
import { Snackbar as MuiSnackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useStore } from '@cobuildlab/react-simple-state';
import { openSnackbarStore } from './snackbar-events';
import { closeSnackbarAction } from './snackbar-actions';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Snackbar: React.FC = () => {
  const { isOpen, message, type } = useStore(openSnackbarStore);
  return (
    <MuiSnackbar
      open={isOpen}
      autoHideDuration={10000}
      onClose={() => closeSnackbarAction()}
    >
      <Alert onClose={() => closeSnackbarAction()} severity={type}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: message as string }} />
      </Alert>
    </MuiSnackbar>
  );
};
