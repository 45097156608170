import { createAction } from '@cobuildlab/react-simple-state';

import { fetchUserEvent, OnTokenEvent } from '../auth/auth-events';
import axios from 'axios';
import { BACKEND_URL } from '../../shared/contants';
import {
  updateAuth0UserErrorEvent,
  updateAuth0UserEvent,
} from './auth0-events';

export const updateAuth0User = createAction(
  updateAuth0UserEvent,
  updateAuth0UserErrorEvent,
  async (data: { email?: string; password?: string }) => {
    const token = OnTokenEvent.get()?.token;
    const user = fetchUserEvent.get()?.user;
    const response = await axios.post(
      `${BACKEND_URL}/auth0/update/${user?.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { user: response.data };
  },
);
