import React from 'react';
import { SideMenu } from './SideMenu';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme, Box } from '@mui/material';
import { TopMenu } from './TopMenu';

interface StylesProps {
  drawerWidth: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#F3F5F8',
      height: '100vh',
      padding: '20px',
    },
    content: {
      flexGrow: 1,
      borderRadius: 10,
      height: 'calc(100vh - 110px)',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflow: 'auto',
    },
    children: {
      paddingTop: 15,
      height: '100%!important',
    },
  }),
);

type LayoutProps = {
  children: React.ReactNode;
};
export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles({ drawerWidth: 180 });
  return (
    <Box className={classes.root}>
      <TopMenu />
      <SideMenu />
      <Box
        className={classes.content}
        sx={{ margin: { xs: '70px auto 0 auto' } }}
        component="main"
      >
        <div className={classes.toolbar} />
        <div className={classes.children}>{children}</div>
      </Box>
    </Box>
  );
};
