import React from 'react';
import { Risk } from '../risk-types';
import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';

type RiskCustomItemProps = {
  risk: Risk;
  onSelected: (risk: Risk) => void;
  selected?: Risk;
  onChangeValue: (value: string) => void;
};
export const RiskCustomItem: React.FC<RiskCustomItemProps> = (
  {
    risk,
    onSelected,
    selected,
    onChangeValue,
  }) => {

  const getColor = (): string => {
    const value = risk?.limit || 0;
    if (value > 0.5 && value < 1) return '#FFFF00';

    else if (value >= 1) return '#FF0000';

    return '#44DD29';
  };


  return (
    <Box
      style={{
        cursor: 'pointer',
        height: '240px',
        position: 'relative',
        border: `3px solid ${selected?.id === risk?.id ? '#6BF965' : '#F2F2F2'}`,
      }}
      onClick={() => onSelected(risk as Risk)}
    >
      <Box color={'black'} style={{ padding: '30px 20px' }}>
        <Typography textAlign={'center'} fontWeight={600} marginBottom={'10px'}>
          Custom
        </Typography>
        <LinearProgress
          sx={{
            height: 10,
            borderRadius: 5,

            backgroundColor: '#E1E1E1',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getColor(),
              borderRadius: 5,
            },
          }}
          variant='determinate'
          value={(parseFloat(risk?.limit as string) || 0) * 100}
        />
        <Box mt={2}>
          <InputDefault variant='outlined' value={selected?.limit || risk.limit} name='limit'
                        onChangeData={(_, value) => {
                          onChangeValue(value);
                        }} />
        </Box>

        <Typography textAlign={'center'} fontSize={12} marginTop={'10px'}>
          {risk.description}
        </Typography>
      </Box>
      {selected?.id === risk?.id ? (
        <Box
          position={'absolute'}
          top={'-14px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          style={{ backgroundColor: 'transparent', width: '100%' }}
        >
          <CheckCircleIcon
            fontSize='medium'
            style={{
              color: '#6BF965',
              backgroundColor: 'white',
              borderRadius: '100%',
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};
