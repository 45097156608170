import { User } from '../auth/auth-types';

export enum StrategyType {
  AGAINST = 'AGAINST',
  FAVOR = 'FAVOR',
  SAFETY_PROFIT = 'SAFETY_PROFIT',
  RED_AGAINST = 'RED_AGAINST',
  SAFETY_LOSS = 'SAFETY_LOSS',
  CYCLES = 'CYCLES',
}

export type Strategy = {
  symbol: string;
  favorMovement: number;
  againstMovement: number;
  safetyLoss: number;
  safetyProfit: number;
  redAgainst: number;
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  strategyHistory?: StrategyHistory[];
  strategyConfiguration?: StrategyConfiguration[];
  time?: StrategyTime[];
  cycles: number;
};

export type StrategyHistory = {
  id?: number;
  strategyId?: number;
  newValue?: number;
  oldValue?: number;
  strategyType?: StrategyType;
  createdAt?: string;
  updatedAt?: string;
};

export type StrategyConfiguration = {
  id?: string;
  user?: User;
  strategy?: Strategy;
  userId?: string;
  strategyId?: string;
};

export type StrategyTime = {
  id?: string;
  startDate?: string;
  endDate?: string;
  strategyId?: string;
  strategy: Strategy;
};
