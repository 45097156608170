import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { InputDefault } from '../../shared/components/inputs/InputDefault';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';

import { NewMetatraderAccountDto } from 'metaapi.cloud-sdk/dists/esm';
import {
  CREATE_META_API_ACCOUNT_DEFAULT_VALUES,
  PLATFORM_LIST,
} from '../meta-api-cloud/meta-api-cloud-constants';
import { SelectDefault } from '../../shared/components/inputs/SelectDefault';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import {
  updateMetaApiAccount,
  updateMetaApiBroker,
} from '../meta-api-cloud/meta-api-cloud-actions';
import { UpdateMetaApiBrokerData } from '../meta-api-cloud/meta-api-cloud-types';
import { fetchCurrentUser } from '../auth/auth-actions';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';
import { useMetaCloudHook } from '../meta-api-cloud/meta-api-cloud-hooks';
import { useTheme } from '@mui/material/styles';
import { fetchUserEvent } from '../auth/auth-events';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#44DD29!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#44DD29!important',
  },
  minHeight: 45,
});

export const SettingBroker: React.FC = () => {

  const { metaApiAccount } = useEvent(fetchUserEvent);
  const [account, setAccount] = useState<NewMetatraderAccountDto>(
    CREATE_META_API_ACCOUNT_DEFAULT_VALUES,
  );
  const [currentAccount, setCurrentAccount] = useState<UpdateMetaApiBrokerData>(
    {
      password: '',
    },
  );

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const { connection } = useMetaCloudHook();
  console.log({ connection });
  const [callCurrentUser] = useCallAction(fetchCurrentUser);

  const [callUpdateMetaApiAccount, loadingUpdate] = useCallAction(
    updateMetaApiAccount,
    {
      onCompleted: () => {
        callCurrentUser();
        openSnackbarAction('Update account successfully!', TypeMessage.SUCCESS);
      },
      onError: () => {
        openSnackbarAction('Error to update account', TypeMessage.ERROR);
      },
    },
  );

  const [callUpdateMetaApiBroker, loadingBroker] = useCallAction(
    updateMetaApiBroker,
    {
      onCompleted: () => {
        callCurrentUser();
        openSnackbarAction('Update account successfully!', TypeMessage.SUCCESS);
      },
      onError: () => {
        openSnackbarAction('Error to update account', TypeMessage.ERROR);
      },
    },
  );


  const onChangeCurrentAccount = (field: string, value: string): void => {
    setCurrentAccount({
      ...currentAccount,
      [field as keyof UpdateMetaApiBrokerData]: value,
    });
  };

  const onChange = (field: string, value: string): void => {
    setAccount({
      ...account,
      [field as keyof NewMetatraderAccountDto]: value,
    });
  };

  return (
    <Box pt={2}>
      <Grid container>
        <Grid item xs={isMd ? 5 : 12} mr={isMd ? 4 : 0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Update to a new broker account</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputDefault
                labelName='Account Name'
                name='name'
                value={account.name}
                onChangeData={onChange}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <InputDefault
                labelName='Login'
                name='login'
                value={account.login}
                onChangeData={onChange}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <InputDefault
                labelName='Password'
                name='password'
                type='password'
                value={account.password}
                onChangeData={onChange}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <InputDefault
                labelName='Server'
                name='server'
                value={account.server}
                onChangeData={onChange}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <SelectDefault
                labelName='MT version'
                name='platform'
                value={account.platform}
                onChangeData={onChange}
                variant='outlined'
                options={PLATFORM_LIST}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent={'center'}
            >
              <StyledButton
                variant='contained'
                color='secondary'
                fullWidth
                onClick={() => callUpdateMetaApiAccount(account)}
                isLoading={loadingUpdate}
              >
                UPDATE ACCOUNT
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation='vertical'
          flexItem
          sx={{ display: { md: 'block', xs: 'none' } }}
        />

        <Grid item xs={isMd ? 6 : 12}>
          <Box px={isMd ? 4 : 0} mt={isMd ? 8 : 2}>
            <Paper elevation={1}>
              <Box p={isMd ? 6 : 2}>
                <Grid
                  container
                  spacing={2}
                  display='flex'
                  justifyContent='center'
                >
                  {connection?.terminalState.accountInformation && (
                    <Grid item xs={12}>
                      <Paper elevation={1}>
                        <Box p={4} textAlign='center'>
                          <Box>
                            {
                              connection?.terminalState.accountInformation
                                .server
                            }
                          </Box>
                          <Box fontWeight='bold'>
                            {
                              connection?.terminalState.accountInformation
                                .broker
                            }
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <InputDefault
                      labelName='Login'
                      name='login'
                      disabled
                      value={metaApiAccount?.login}
                      onChangeData={onChangeCurrentAccount}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputDefault
                      labelName='Password'
                      name='password'
                      type='password'
                      value={currentAccount.password}
                      onChangeData={onChangeCurrentAccount}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <StyledButton
                      variant='contained'
                      color='secondary'
                      fullWidth
                      onClick={() => callUpdateMetaApiBroker(currentAccount)}
                      isLoading={loadingBroker}
                      style={{
                        padding: isMd ? 0 : '5px 0!important',
                      }}
                    >
                      UPDATE CURRENT BROKER INFORMATION
                    </StyledButton>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
