import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Provider } from './MetaApiCloudContext';
import { fetchUserEvent } from '../auth/auth-events';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { connectToMetaApi } from './meta-api-cloud-actions';
import { StreamingMetaApiConnectionInstance } from 'metaapi.cloud-sdk';
import { fetchStrategiesEvent } from '../strategy/strategy-events';
import { fetchStrategies } from '../strategy/strategy-actions';

type MetaApiCloudProviderProp = {
  children: ReactNode;
};
export const MetaApiCloudProvider: React.FC<MetaApiCloudProviderProp> = ({
                                                                           children,
                                                                         }) => {
  const [accountId, setAccountId] = useState<string | undefined>(undefined);
  const { user, metaApiAccount } = useEvent(fetchUserEvent);
  const { strategies } = useEvent(fetchStrategiesEvent);
  const [callFetchStrategies] = useCallAction(fetchStrategies);

  const [isConnectedToBroker, setIsConnectedToBroker] =
    useState<boolean>(false);
  const [connection, setConnection] = useState<
    StreamingMetaApiConnectionInstance | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    const connectionToMetaApi = await connectToMetaApi(accountId as string);
    const terminalState = connectionToMetaApi.terminalState;
    setIsConnectedToBroker(terminalState.connectedToBroker);
    setConnection(connectionToMetaApi);
    for (const strategy of strategies) {
      if (strategy.strategyConfiguration) await connectionToMetaApi.subscribeToMarketData(strategy.symbol, []);
    }
  }, [accountId, strategies]);

  useEffect(() => {
    if (user && metaApiAccount?.accountId) {
      console.log({ user }, metaApiAccount.accountId);

      setAccountId(metaApiAccount?.accountId);
      callFetchStrategies();
    }
  }, [user, callFetchStrategies, metaApiAccount?.accountId]);

  useEffect(() => {
    if (accountId && strategies.length) fetchData();
  }, [accountId, fetchData, strategies.length]);

  const values = {
    isConnectedToBroker,
    connection,
    accountId,
  };

  return <Provider value={values}>{children}</Provider>;
};
