import React, { useState } from 'react';
import { Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchUserEvent } from '../auth/auth-events';
import { InputDefault } from '../../shared/components/inputs/InputDefault';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { updateAuth0User } from '../auth0/auth0-actions';
import { fetchCurrentUser } from '../auth/auth-actions';
import { useTheme } from '@mui/material/styles';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#44DD29!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#44DD29!important',
  },
  height: 45,
});

type DataType = {
  email?: string;
  password?: string;
};

const INITIAL_VALUES = {
  email: '',
  password: '',
};
export const SettingAccount: React.FC = () => {
  const { user } = useEvent(fetchUserEvent);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [data, setData] = useState<DataType>(INITIAL_VALUES);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const [callCurrentUser] = useCallAction(fetchCurrentUser, {
    onCompleted: () => {
      setLoadingEmail(false);
      setLoadingPassword(false);
      setData(INITIAL_VALUES);
    },
  });

  const [callUpdateAuth0Email] = useCallAction(updateAuth0User, {
    onCompleted: () => callCurrentUser(),
    onError: () => {
      setLoadingEmail(false);
    },
  });
  const [callUpdateAuth0Password] = useCallAction(updateAuth0User, {
    onCompleted: () => callCurrentUser(),
    onError: () => {
      setLoadingPassword(false);
    },
  });
  const onChange = (field: string, value: string): void => {
    setData({
      ...data,
      [field as keyof DataType]: value,
    });
  };

  return (
    <Box pt={2}>
      <Grid container>
        <Grid item xs={isMd ? 8 : 12}>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Login Information</Typography>
              </Grid>
              <Grid item xs={isMd ? 8 : 12}>
                <InputDefault
                  variant="outlined"
                  labelName="Current Email"
                  disabled
                  value={user?.email}
                />
              </Grid>
              <Grid item xs={isMd ? 8 : 12}>
                <InputDefault
                  variant="outlined"
                  labelName="New Email"
                  value={data.email}
                  name="email"
                  onChangeData={onChange}
                />
              </Grid>
              <Grid item xs={isMd ? 8 : 12}>
                <StyledButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  isLoading={loadingEmail}
                  onClick={() => {
                    setLoadingEmail(true);
                    callUpdateAuth0Email({
                      email: data.email,
                    });
                  }}
                >
                  Update Email Address
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Password Information</Typography>
              </Grid>
              <Grid item xs={isMd ? 8 : 12}>
                <InputDefault
                  variant="outlined"
                  labelName="New Password"
                  name="password"
                  value={data?.password}
                  onChangeData={onChange}
                />
              </Grid>
              <Grid item xs={isMd ? 8 : 12}>
                <StyledButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  isLoading={loadingPassword}
                  onClick={() => {
                    setLoadingPassword(true);
                    callUpdateAuth0Password({ password: data.password });
                  }}
                >
                  Update Password
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
