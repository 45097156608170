import { createStore } from '@cobuildlab/react-simple-state';
import { SnackbarType, TypeMessage } from './snackbar-types';

export const openSnackbarStore = createStore<SnackbarType>({
  initialValue: {
    isOpen: false,
    message: '',
    type: TypeMessage.SUCCESS,
  },
});
