import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: '#FE0000',
  //   },
  //   secondary: {
  //     main: '#21272C',
  //   },
  // },
  //"PT Sans",helvetica,arial,sans-serif
  typography: {
    fontFamily: [
      'Barlow',
      '"PT Sans"',
      'helvetica',
      'arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#D5DCE6',
            color: '#fff',
          },
          '&': {
            border: '1px solid #CCCCCC',
            color: '#999999',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontWeight:700,
          '&': {
            cursor: 'pointer',
          },
        },
      },
    },
  },
});
