import { useEffect, useState } from 'react';
import { socket } from './socket';

export const useGlobalSocket = (): {
  strategyStatus: boolean;
} => {
  const [strategyStatus, setStrategyStatus] = useState(false);


  useEffect(() => {
    // no-op if the socket is already connected
    socket.connect();
    socket.on('session', () => {
    });

    socket.on('strategy_status', (status) => {
      console.log('strategy_status', status);
      setStrategyStatus(status);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return {

    strategyStatus,
  };
};
