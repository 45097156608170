import { PairType } from './pair-types';

export const sortPairs = (positions: PairType[]): PairType[] => {
  return positions.sort(function (a, b) {
    if (a.symbol < b.symbol) {
      return -1;
    }
    if (a.symbol > b.symbol) {
      return 1;
    }
    return 0;
  });
};
