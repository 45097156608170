import {
  ScheduleType,
  TradingVolumenEnum,
  TradingVolumenType,
} from './components/layout/layout-types';

export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH_CLIENT_DOMAIN =
  process.env.REACT_APP_AUTH0_CLIENT_DOMAIN || '';

export const DRAWER_WIDTH = 150;
export const MIN_DRAWER_WIDTH = 70;

export const META_API_TOKEN = process.env.REACT_APP_META_API_TOKEN || '';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || '';
export const DIGITALOCEAN_TOKEN =
  process.env.REACT_APP_DIGITALOCEAN_TOKEN || '';

export const TRADING_VOLUMEN: TradingVolumenType[] = [
  {
    times: {
      startDate: '2:00 AM',
      endDate: '2:00 PM',
    },
    volumen: TradingVolumenEnum.High,
  },
  {
    times: {
      startDate: '2:00 PM',
      endDate: '4:00 PM',
    },
    volumen: TradingVolumenEnum.Medium,
  },
  {
    times: {
      startDate: '4:00 PM',
      endDate: '8:00 PM',
    },
    volumen: TradingVolumenEnum.Low,
  },
  {
    times: {
      startDate: '8:00 PM',
      endDate: '10:00 PM',
    },
    volumen: TradingVolumenEnum.Medium,
  },
  {
    times: {
      startDate: '10:00 PM',
      endDate: '11:59 AM',
    },
    volumen: TradingVolumenEnum.Low,
    addDays: 1,
  },
  {
    times: {
      startDate: '12:00 AM',
      endDate: '2:00 AM',
    },
    volumen: TradingVolumenEnum.Low,
    addDays: 1,
  },
];

export const SCHEDULE: ScheduleType[] = [
  {
    times: {
      startDate: '7:00 PM',
      endDate: '11:59 PM',
    },
    city: 'Tokyo',
    image: '/tokyo-icon.png',
    addDays: 1,
  },

  {
    times: {
      startDate: '12:00 AM',
      endDate: '4:00 AM',
    },
    city: 'Tokyo',
    image: '/tokyo-icon.png',
    addDays: 1,
  },

  {
    times: {
      startDate: '3:00 AM',
      endDate: '12:00 PM',
    },
    city: 'London',
    image: '/london-icon.png',
  },
  {
    times: {
      startDate: '8:00 AM',
      endDate: '5:00 PM',
    },
    city: 'New York',
    image: '/usa-icon.png',
  },
  {
    times: {
      startDate: '3:00 PM',
      endDate: '11:59 PM',
    },
    city: 'Sydney',
    image: '/sydney-icon.png',
  },
];
