import React, { ReactNode, useEffect } from 'react';
import { Loading } from '../../shared/components/Loading';
import { useSession, useSetupAuth0Token } from './auth-hooks';
import { useEvent } from '@cobuildlab/react-simple-state';
import { fetchUserEvent } from './auth-events';
import { useNavigate } from 'react-router-dom';
import { changeStep } from '../onboarding/onboarding-actions';
import { MetaApiCloudProvider } from '../meta-api-cloud/MetaApiCloudProvider';

type TokenSessionProps = {
  children: ReactNode;
};
export const TokenSession: React.FC<TokenSessionProps> = ({ children }) => {
  const { isLoading } = useSetupAuth0Token();

  const navigate = useNavigate();

  const { loading: sessionLoading } = useSession();

  const { user } = useEvent(fetchUserEvent);

  useEffect(() => {
    const isCompletedOnboarding =
      user &&
      user.meta.find(
        (_meta) => _meta.key === 'is_completed' && _meta.value === '0',
      );
    if (isCompletedOnboarding) {
      const onboardingStep = user.meta.find(
        (_meta) => _meta.key === 'on_boarding_step',
      );
      if (onboardingStep) {
        changeStep(parseInt(onboardingStep.value as string));
        navigate('/onboarding');
      }
    }
  }, [navigate, user]);

  if (isLoading || sessionLoading) return <Loading />;

  return <MetaApiCloudProvider>{children}</MetaApiCloudProvider>;
};
