import { createAction } from '@cobuildlab/react-simple-state';
import { OnTokenEvent } from '../auth/auth-events';
import axios from 'axios';
import { UpdateMetaInput } from './meta-types';
import { updateMetaErrorEvent, updateMetaEvent } from './meta-events';
import { BACKEND_URL } from '../../shared/contants';

export const updateMeta = createAction(
  updateMetaEvent,
  updateMetaErrorEvent,
  async (data: UpdateMetaInput) => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.post(
      `${BACKEND_URL}/meta/update/${data?.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return { meta: response.data };
  },
);
