import { Platform } from 'metaapi.cloud-sdk';

export const CREATE_META_API_ACCOUNT_DEFAULT_VALUES = {
  baseCurrency: 'USD',
  copyFactoryResourceSlots: 1,
  copyFactoryRoles: undefined,
  keywords: undefined,
  login: '',
  magic: 0,
  manualTrades: false,
  metadata: undefined,
  metastatsApiEnabled: true,
  name: '',
  password: '',
  platform: undefined,
  provisioningProfileId: '',
  quoteStreamingIntervalInSeconds: 2.5,
  region: 'new-york',
  reliability: undefined,
  resourceSlots: 1,
  riskManagementApiEnabled: false,
  server: '',
  slippage: 0,
  symbol: '',
  tags: undefined,
  type: undefined,
};

export const PLATFORM_LIST: { value: Platform; label: string }[] = [
  {
    value: 'mt4',
    label: 'MT4',
  },
  {
    value: 'mt5',
    label: 'MT5',
  },
];

export const META_STAT_TRADES = {
  tradesCloseInLose: 0,
  variationTradesCloseInLose: 0,
  tradesCloseInLoseDifference: 0,
  tradesCloseInProfitDifference: 0,
  variationTradeCloseInProfit: 0,
  tradesCloseInProfit: 0,
  variationPair: 0,
  bestPair: {
    tradesCount: 0,
    profit: 0,
    currency: '-',
    closeTime: '-',
  },
  bestPairDifference: {
    tradesCount: 0,
    profit: 0,
    currency: '-',
    closeTime: '',
  },
};
