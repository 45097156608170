import React from 'react';
import { Grid } from '@mui/material';
import { User } from '../../auth/auth-types';
import moment from 'moment';

type UserItemProps = {
  user?: User;
};

export const UserItem: React.FC<UserItemProps> = ({ user }) => {
  return (
    <Grid container py={'5px'} px={'15px'} borderBottom={'1px solid #F0F0F0'}>
      <Grid item xs={3} textAlign="center">{`${user?.firstName || ''} ${
        user?.lastName || ''
      }`}</Grid>
      <Grid item xs={3} textAlign="center">
        {user?.email}
      </Grid>
      <Grid item xs={3} textAlign="center">
        {user?.role.name}
      </Grid>
      <Grid item xs={3} textAlign="center">
        {moment(user?.createdAt).format('YYYY-MM-DD')}
      </Grid>
    </Grid>
  );
};
