import { createEvent } from '@cobuildlab/react-simple-state';
import { User } from '../auth/auth-types';
import { Risk } from '../risk/risk-types';

export const updateUserEvent = createEvent<{ user: User | undefined }>({
  initialValue: {
    user: undefined,
  },
});

export const updateUserErrorEvent = createEvent<Error>();

export const createUserRiskEvent = createEvent<{ user: User | undefined }>({
  initialValue: {
    user: undefined,
  },
});
export const createUserRiskErrorEvent = createEvent<Error>();

export const updateUserRiskEvent = createEvent<{ risk: Risk | undefined }>({
  initialValue: {
    risk: undefined,
  },
});
export const updateUserRiskErrorEvent = createEvent<Error>();

export const fetchUsersEvent = createEvent<{ users: User[] }>({
  initialValue: {
    users: [],
  },
});

export const fetchUsersErrorEvent = createEvent();
