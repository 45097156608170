import { createEvent } from '@cobuildlab/react-simple-state';
import {
  DigitaloceanDeployment,
  DigitaloceanDeployments,
} from './digitalocean-types';

export const digitaloceanResetServerEvent = createEvent<{
  deployment?: DigitaloceanDeployment;
}>({
  initialValue: {
    deployment: undefined,
  },
});

export const digitaloceanResetServerErrorEvent = createEvent();

export const digitaloceanDeploymentsEvent =
  createEvent<DigitaloceanDeployments>({
    initialValue: {
      deployments: [],
    },
  });

export const digitaloceanDeploymentsErrorEvent = createEvent();
