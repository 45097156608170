import {
  SynchronizationListener,
  MetatraderSymbolPrice,
} from 'metaapi.cloud-sdk';
import { fetchSymbolPriceStore } from './meta-api-cloud-events';

class MetaApiListener extends SynchronizationListener {
  onSymbolPriceUpdated(
    instanceIndex: string,
    price: MetatraderSymbolPrice,
  ): Promise<void> {
    fetchSymbolPriceStore.dispatch({ price });
    return super.onSymbolPriceUpdated(instanceIndex, price);
  }
}

export { MetaApiListener };
