import React, { useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { NewMetatraderAccountDto } from 'metaapi.cloud-sdk';
import {
  CREATE_META_API_ACCOUNT_DEFAULT_VALUES,
  PLATFORM_LIST,
} from '../../../meta-api-cloud/meta-api-cloud-constants';
import { createMetaApiAccount } from '../../../meta-api-cloud/meta-api-cloud-actions';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { InputDefault } from '../../../../shared/components/inputs/InputDefault';
import { SelectDefault } from '../../../../shared/components/inputs/SelectDefault';
import { openSnackbarAction } from '../../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../../shared/components/snackbar/snackbar-types';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#44DD29!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#44DD29!important',
  },
  height: 45,
});

type CreateAccountFormProps = {
  onSuccess: () => void;
  successLoading?: boolean;
};

export const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  onSuccess,
  successLoading,
}) => {
  const [account, setAccount] = useState<NewMetatraderAccountDto>(
    CREATE_META_API_ACCOUNT_DEFAULT_VALUES,
  );

  const [callCreateMetaApiAccount, loading] = useCallAction(
    createMetaApiAccount,
    {
      onCompleted: () => {
        onSuccess();
      },
      onError: (data) => {
        openSnackbarAction(
          data.response?.data.message as string,
          TypeMessage.ERROR,
        );
      },
    },
  );

  const onChange = (field: string, value: string): void => {
    setAccount({
      ...account,
      [field as keyof NewMetatraderAccountDto]: value,
    });
  };

  return (
    <>
      <Box mt={2} mb={2}>
        <Typography textAlign={'center'} fontWeight={600} fontSize={24}>
          Sign In to your broker
        </Typography>
        <Typography textAlign={'center'} fontSize={16}>
          Input your information to connect your broker
        </Typography>
      </Box>
      <Box>
        <Paper elevation={3}>
          <Box p={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputDefault
                  labelName="Account Name"
                  name="name"
                  value={account.name}
                  onChangeData={onChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <InputDefault
                  labelName="Login"
                  name="login"
                  value={account.login}
                  onChangeData={onChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <InputDefault
                  labelName="Password"
                  name="password"
                  type="password"
                  value={account.password}
                  onChangeData={onChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <InputDefault
                  labelName="Server"
                  name="server"
                  value={account.server}
                  onChangeData={onChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDefault
                  labelName="MT version"
                  name="platform"
                  value={account.platform}
                  onChangeData={onChange}
                  variant="outlined"
                  options={PLATFORM_LIST}
                />
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <StyledButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => callCreateMetaApiAccount(account)}
                  isLoading={loading || successLoading}
                >
                  Continue
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
