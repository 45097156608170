import React, { ReactNode } from 'react';
import { Card, CardHeader, CardContent, Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loading } from '../../../shared/components/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: '#000',
    padding: theme.spacing(2),
    color: 'white',
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
  },
  content: {
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type DashboardCardItemProps = {
  title?: string;
  content?: string | number | ReactNode;
  loading?: boolean;
  subContent?: ReactNode;
};

export const DashboardCardItem: React.FC<DashboardCardItemProps> = ({
  title = '',
  content = '',
  loading,
  subContent,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        classes={{
          title: classes.title,
        }}
        className={classes.header}
        title={title}
      />

      <CardContent className={classes.content}>
        {loading ? (
          <Loading height="auto" />
        ) : (
          <>
            <Box fontSize={40} fontWeight="bold">
              {content}
            </Box>
            {subContent}
          </>
        )}
      </CardContent>
    </Card>
  );
};
