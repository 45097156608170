import React from 'react';
import { Box, Typography, useMediaQuery, Grid } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { StrategyHistory } from '../../strategy/strategy-types';
import { getDecimals, getStrategyType } from '../setting-utils';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
// import moment from 'moment';

type StrategyHistoryItemProps = {
  strategyHistoryItem: StrategyHistory;
  active: boolean;
};

export const StrategyHistoryItem: React.FC<StrategyHistoryItemProps> = ({
  strategyHistoryItem,
  active,
}) => {
  const isIncrement =
    (strategyHistoryItem?.newValue || 0) - (strategyHistoryItem?.oldValue || 0);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  if (isMd)
    return (
      <Box
        paddingY={'20px'}
        style={{ background: `${active ? '#E4FFDF' : '#fff'}` }}
        my={'10px'}
        border={'1px solid #F3F3F3'}
        width={500}
        px={'30px'}
        display={'flex'}
      >
        <Box
          position={'relative'}
          borderRight={'1px solid #F3F3F3'}
          width={'10px'}
        >
          <Box
            style={{ background: `${active ? '#25BC42' : '#fff'}` }}
            border={'1px solid #F3F3F3'}
            borderRadius={'100%'}
            width={'10px'}
            height={'10px'}
            position={'absolute'}
            right={'-6px'}
            top={'12px'}
          ></Box>
        </Box>
        <Box paddingLeft={'10px'}>
          <ChangeCircleIcon fontSize="large" />
        </Box>
        <Box paddingLeft={'10px'}>
          <Typography fontWeight={700}>
            {moment(strategyHistoryItem?.createdAt).format('LLL')}
          </Typography>
          <Typography style={{ color: active ? '#25BC42' : '#000' }}>
            {`${getStrategyType(strategyHistoryItem?.strategyType)} was ${
              isIncrement > 0 ? 'increased' : 'decreased'
            } ${Math.abs(isIncrement).toFixed(
              getDecimals(strategyHistoryItem?.newValue || 0),
            )}`}
          </Typography>
          <Box
            borderRadius={5}
            p={1}
            style={{ backgroundColor: '#f5f5f5' }}
            display="flex"
            width={200}
            minWidth={200}
          >
            <Typography fontSize={12}>
              {`${getStrategyType(strategyHistoryItem?.strategyType)}`}:
              <span
                style={{
                  textDecoration: 'line-through',
                  marginRight: 5,
                }}
              >
                {strategyHistoryItem?.oldValue || 0}
              </span>
              <span style={{ color: '#25BC42' }}>
                {strategyHistoryItem?.newValue || 0}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    );

  return (
    <Box
      paddingY={'20px'}
      style={{ background: `${active ? '#E4FFDF' : '#fff'}` }}
      my={'10px'}
      border={'1px solid #F3F3F3'}
      px={'30px'}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box position={'relative'} width={'10px'} mr={1}>
              <Box
                style={{ background: `${active ? '#25BC42' : '#fff'}` }}
                border={'1px solid #F3F3F3'}
                borderRadius={'100%'}
                width={'10px'}
                height={'10px'}
              ></Box>
            </Box>
            <Box>
              <ChangeCircleIcon fontSize="large" />
            </Box>
            <Box>
              <Typography fontWeight={700}>
                {moment(strategyHistoryItem?.createdAt).format('LLL')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ color: active ? '#25BC42' : '#000' }}>
            {`${getStrategyType(strategyHistoryItem?.strategyType)} was ${
              isIncrement > 0 ? 'increased' : 'decreased'
            } ${Math.abs(isIncrement).toFixed(
              getDecimals(strategyHistoryItem?.newValue || 0),
            )}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            borderRadius={5}
            style={{ backgroundColor: '#f5f5f5' }}
            display="flex"
            width={200}
            minWidth={200}
          >
            <Typography fontSize={12}>
              {`${getStrategyType(strategyHistoryItem?.strategyType)}`}:
              <span
                style={{
                  textDecoration: 'line-through',
                  marginRight: 5,
                }}
              >
                {strategyHistoryItem?.oldValue || 0}
              </span>
              <span style={{ color: '#25BC42' }}>
                {strategyHistoryItem?.newValue || 0}
              </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
