export type DigitaloceanDeployment = {
  id: string;
  created_at: string;
  phase: DeploymentPhase;
  cause_details?: {
    digitalocean_user_action?: {
      user: {
        email: string;
        full_name: string;
      };
    };
    git_push?: {
      username: string;
    };
  };
};

export type DigitaloceanDeployments = {
  deployments: DigitaloceanDeployment[];
};

export enum DeploymentPhase {
  unknown = 'UNKNOWN',
  active = 'ACTIVE',
  superseded = 'SUPERSEDED',
  deploying = 'DEPLOYING',
  error = 'ERROR',
  canceled = 'CANCELED',
}
