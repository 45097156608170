import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import {
  digitaloceanDeployments,
  digitaloceanResetServer,
} from '../digitalocean/digitalocean-actions';
import { styled } from '@mui/styles';
import { digitaloceanDeploymentsEvent } from '../digitalocean/digitalocean-events';
import { SettingBuildItem } from './components/SettingBuildItem';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#44DD29!important',
  border: 'none!important',
  color: 'white!important',
  minWidth: 130,
  '&:disabled': {
    backgroundColor: '#44DD29!important',
  },
  height: 45,
});
export const SettingBuild: React.FC = () => {
  const { deployments } = useEvent(digitaloceanDeploymentsEvent);

  const [callDeploymentsAction] = useCallAction(digitaloceanDeployments);
  const [callAction, loading] = useCallAction(digitaloceanResetServer, {
    onCompleted: () => {
      openSnackbarAction('Rebuild successfully', TypeMessage.SUCCESS);
      callDeploymentsAction();
    },
  });
  useEffect(() => {
    callDeploymentsAction();
  }, [callDeploymentsAction]);


  const [item] = deployments;

  return (
    <Box pt={2}>
      <Grid container>
        <Grid item xs={12} mb={1}>
          <StyledButton isLoading={loading} onClick={() => callAction()}>
            RESTART SERVER
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <SettingBuildItem deployment={item} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
