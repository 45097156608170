import { createStoreAction } from '@cobuildlab/react-simple-state';
import { drawerSidebarEvent } from './layout-events';

export const openDrawerSidebar = createStoreAction(
  drawerSidebarEvent,
  (prev, isOpen: boolean) => ({
    ...prev,
    isOpen,
  }),
);
