import React from 'react';
import { Risk } from '../risk-types';
import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type RiskItemProps = {
  risk: Risk;
  onSelected: (risk: Risk) => void;
  selected?: Risk;
};
export const RiskItem: React.FC<RiskItemProps> = ({
  risk,
  onSelected,
  selected,
}) => {
  let color = '';
  let title = '';
  const limit = parseFloat(risk.limit as string);
  switch (limit) {
    case 0.5:
      color = '#FFFF00';
      title = 'Low Risk';
      break;
    case 1.0:
      color = '#FF0000';
      title = 'High Risk';
      break;
    default:
      color = '#44DD29';
      title = 'Medium Risk';
  }

  return (
    <Box
      style={{
        cursor: 'pointer',
        height: '240px',
        position: 'relative',
        border: `3px solid ${selected?.id === risk.id ? '#6BF965' : '#F2F2F2'}`,
      }}
      onClick={() => onSelected(risk)}
    >
      <Box color={'black'} style={{ padding: '30px 20px' }}>
        <Typography textAlign={'center'} fontWeight={600} marginBottom={'10px'}>
          {title}
        </Typography>
        <LinearProgress
          sx={{
            height: 10,
            borderRadius: 5,

            backgroundColor: '#E1E1E1',
            '& .MuiLinearProgress-bar': {
              backgroundColor: color,
              borderRadius: 5,
            },
          }}
          variant="determinate"
          value={(limit || 0) * 100}
        />
        <Box mt={2}>
          <Typography textAlign={'center'} fontSize={16}>
            ({risk?.limit || 0})
          </Typography>
        </Box>

        <Typography textAlign={'center'} fontSize={12} marginTop={'10px'}>
          {risk.description}
        </Typography>
      </Box>
      {selected?.id === risk.id ? (
        <Box
          position={'absolute'}
          top={'-14px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          style={{ backgroundColor: 'transparent', width: '100%' }}
        >
          <CheckCircleIcon
            fontSize="medium"
            style={{
              color: '#6BF965',
              backgroundColor: 'white',
              borderRadius: '100%',
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};
