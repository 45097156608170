import { OnTokenEvent } from '../auth/auth-events';
import { BACKEND_URL } from '../../shared/contants';
import { createAction } from '@cobuildlab/react-simple-state';
import { fetchRisksErrorEvent, fetchRisksEvent } from './risk-events';
import axios from 'axios';

export const fetchRisks = createAction(
  fetchRisksEvent,
  fetchRisksErrorEvent,
  async () => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.get(`${BACKEND_URL}/risk/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { risks: response.data };
  },
);
