import React, { useEffect, useState } from 'react';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchRisks } from '../risk/risk-actions';
import { Grid, Typography } from '@mui/material';
import { RiskItem } from '../risk/components/RiskItem';
import { Risk } from '../risk/risk-types';
import { Loading } from '../../shared/components/Loading';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { fetchRisksEvent } from '../risk/risk-events';
import { fetchUserEvent } from '../auth/auth-events';
import { updateUserRisk } from '../user/user-actions';
import { fetchCurrentUser } from '../auth/auth-actions';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';
import { RiskCustomItem } from '../risk/components/RIskCustomItem';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#44DD29!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#44DD29!important',
  },
  height: 40,
});

export const SettingRisk: React.FC = () => {
  const { risks } = useEvent(fetchRisksEvent);
  const { user } = useEvent(fetchUserEvent);
  const [selectedRisk, setSelectedRisk] = useState<Risk | undefined>(
    user?.risk ? user?.risk : undefined,
  );

  const [loading, setLoading] = useState(true);

  const [callCurrentUser] = useCallAction(fetchCurrentUser, {
    onCompleted: () =>
      openSnackbarAction('Update risk successfully!', TypeMessage.SUCCESS),
  });

  const [callRisks] = useCallAction(fetchRisks, {
    onCompleted: () => setLoading(false),
  });

  useEffect(() => {
    if (loading) callRisks();
  }, [callRisks, loading]);

  const [callUpdateRisk, updateLoading] = useCallAction(updateUserRisk, {
    onCompleted: () => {
      callCurrentUser();
    },
    onError: () => {
      openSnackbarAction('Error to update risk', TypeMessage.ERROR);
    },
  });

  if (loading) return <Loading />;
  console.log({ selectedRisk });
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      px={'10px'}
    >
      <Grid item sm={10} marginTop={'50px'}>
        <Grid container spacing={2}>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Typography fontWeight={700} fontSize={18} textAlign={'center'}>
              Choose your risk profile
            </Typography>
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Typography fontSize={12} textAlign={'center'}>
              Select your risk based on your account capital
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              paddingTop={'20px'}
              container
              columnSpacing={2}
              rowSpacing={2}
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              {risks.map((risk) => {
                if (risk.is_editable) {
                  return (
                    <Grid item md={3} xs={12}>
                      <RiskCustomItem
                        risk={risk}
                        onSelected={(selected) => setSelectedRisk(selected)}
                        selected={selectedRisk}
                        onChangeValue={(value) => {
                          console.log({ value });
                          if (isNaN(parseFloat(value))) return null;
                          console.log('float', { value });

                          setSelectedRisk({
                            ...selectedRisk,
                            limit: value,
                          });
                        }}
                      />
                    </Grid>
                  );
                }
                return (
                  <Grid item md={3} xs={12}>
                    <RiskItem
                      risk={risk}
                      onSelected={(selected) => setSelectedRisk(selected)}
                      selected={selectedRisk}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent={'center'}
              sx={{ marginTop: { xs: 2, md: 4 } }}
            >
              <StyledButton
                variant='contained'
                color='secondary'
                isLoading={updateLoading}
                onClick={() => callUpdateRisk(selectedRisk as Risk)}
                sx={{ width: { xs: '100%!important', md: 180 } }}
              >
                UPDATE
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
