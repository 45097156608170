import { MetatraderPosition } from 'metaapi.cloud-sdk/dist/clients/metaApi/metaApiWebsocket.client';
import { MetaStatTrade } from '../meta-api-cloud/meta-api-cloud-types';

export const calculateMarginProfit = (position: MetatraderPosition): number => {
  if (!position.openPrice) return 0;

  return Math.abs(
    ((position.currentPrice - position.openPrice) * 100) / position.openPrice,
  );
};

export const sortPosition = (
  positions: MetatraderPosition[],
): MetatraderPosition[] => {
  return positions.sort(function (a, b) {
    if (a.symbol < b.symbol) {
      return -1;
    }
    if (a.symbol > b.symbol) {
      return 1;
    }
    return 0;
  });
};

export const calculateTradeMarginProfit = (trade: MetaStatTrade): number => {
  const profit = trade.profit || 0;
  const openPrice = trade.openPrice || 1;

  return Math.abs((profit * 100) / openPrice);
};
