import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  changeStrategyStore,
  createStrategyConfigurationErrorEvent,
  createStrategyConfigurationEvent,
  createStrategyTimeEvent,
  createStrategyTimErrorEvent,
  deleteStrategyConfigurationErrorEvent,
  deleteStrategyConfigurationEvent,
  deleteStrategyTimeEvent,
  deleteStrategyTimErrorEvent,
  fetchStrategiesErrorEvent,
  fetchStrategiesEvent,
  fetchStrategyHistoryErrorEvent,
  fetchStrategyHistoryEvent,
  fetchStrategyPairsErrorEvent,
  fetchStrategyPairsEvent,
  updateStrategyErrorEvent,
  updateStrategyEvent,
} from './strategy-events';
import { fetchUserEvent, OnTokenEvent } from '../auth/auth-events';
import axios from 'axios';
import { BACKEND_URL } from '../../shared/contants';
import { Strategy } from './strategy-types';

export const fetchStrategies = createAction(
  fetchStrategiesEvent,
  fetchStrategiesErrorEvent,
  async (symbol = '') => {
    const token = OnTokenEvent.get()?.token;
    const user = fetchUserEvent.get()?.user;
    const response = await axios.get(
      `${BACKEND_URL}/strategy/list?userId=${user?.id}&symbol=${symbol}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return {
      strategies: response.data,
    };
  },
);

export const fetchStrategyHistories = createAction(
  fetchStrategyHistoryEvent,
  fetchStrategyHistoryErrorEvent,
  async (data: { strategyId: string }) => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.get(`${BACKEND_URL}/strategy/history/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        id: data.strategyId,
      },
    });
    return {
      strategyHistories: response.data,
    };
  },
);

export const changeStrategyAction = createStoreAction(
  changeStrategyStore,
  (prev, strategy: Strategy) => ({
    ...prev,
    strategy,
  }),
);

export const updateStrategy = createAction(
  updateStrategyEvent,
  updateStrategyErrorEvent,
  async (data: Strategy) => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.post(`${BACKEND_URL}/strategy/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      strategy: response.data,
    };
  },
);

export const createStrategyConfiguration = createAction(
  createStrategyConfigurationEvent,
  createStrategyConfigurationErrorEvent,
  async (data: { userId?: string; strategyId?: string }) => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.post(
      `${BACKEND_URL}/strategy/configuration/create`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return {
      strategyConfiguration: response.data,
    };
  },
);

export const deleteStrategyConfiguration = createAction(
  deleteStrategyConfigurationEvent,
  deleteStrategyConfigurationErrorEvent,
  async (data: { id?: string }) => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.post(
      `${BACKEND_URL}/strategy/configuration/delete`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return {
      strategyConfiguration: response.data,
    };
  },
);

export const createStrategyTime = createAction(
  createStrategyTimeEvent,
  createStrategyTimErrorEvent,
  async (data: {
    strategyId?: string;
    startDate?: string;
    endDate?: string;
  }) => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.post(
      `${BACKEND_URL}/strategy/time/create`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return {
      strategy: response.data,
    };
  },
);

export const deleteStrategyTime = createAction(
  deleteStrategyTimeEvent,
  deleteStrategyTimErrorEvent,
  async (data: { id?: string }) => {
    const token = OnTokenEvent.get()?.token;
    const response = await axios.post(
      `${BACKEND_URL}/strategy/time/delete`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return {
      strategy: response.data,
    };
  },
);

export const fetchStrategyPairs = createAction(
  fetchStrategyPairsEvent,
  fetchStrategyPairsErrorEvent,
  async (symbol = '') => {
    const token = OnTokenEvent.get()?.token;
    const user = fetchUserEvent.get()?.user;
    const response = await axios.get(
      `${BACKEND_URL}/strategy/list?userId=${user?.id}&symbol=${symbol}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return {
      strategies: response.data,
    };
  },
);
