import React from 'react';
import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Strategy } from '../../strategy/strategy-types';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeStrategyAction } from '../../strategy/strategy-actions';
import { changeStrategyStore } from '../../strategy/strategy-events';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    box: {
      padding: 10,
      borderRadius: 10,
      marginRight: 10,
      cursor: 'pointer',
    },
  }),
);

type SymbolItemProps = {
  strategy: Strategy;
};
export const SymbolItem: React.FC<SymbolItemProps> = ({ strategy }) => {
  const classes = useStyles();
  const { strategy: selected } = useStore(changeStrategyStore);
  const isSelected = selected?.symbol === strategy.symbol;
  return (
    <Box
      onClick={() => changeStrategyAction(strategy)}
      className={classes.box}
      style={{
        backgroundColor: isSelected ? '#d7ffd2' : 'transparent',
        color: isSelected ? '#207d32' : '#00000061',
        border: isSelected ? 'none' : '1px solid #00000061',
      }}
    >
      {strategy?.symbol}
    </Box>
  );
};
