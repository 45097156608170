import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import { AxiosError } from 'axios';
import {
  Strategy,
  StrategyConfiguration,
  StrategyHistory,
} from './strategy-types';

export const fetchStrategiesEvent = createEvent<{ strategies: Strategy[] }>({
  initialValue: {
    strategies: [],
  },
});

export const fetchStrategiesErrorEvent = createEvent<AxiosError>();

export const changeStrategyStore = createStore<{
  strategy: Strategy | undefined;
}>({
  initialValue: {
    strategy: undefined,
  },
});

export const updateStrategyEvent = createEvent<{
  strategy: Strategy | undefined;
}>({
  initialValue: {
    strategy: undefined,
  },
});

export const updateStrategyErrorEvent = createEvent<AxiosError>();

export const fetchStrategyHistoryEvent = createEvent<{
  strategyHistories: StrategyHistory[];
}>({
  initialValue: {
    strategyHistories: [],
  },
});

export const fetchStrategyHistoryErrorEvent = createEvent<AxiosError>();

export const createStrategyConfigurationEvent = createEvent<{
  strategyConfiguration: StrategyConfiguration | undefined;
}>({
  initialValue: {
    strategyConfiguration: undefined,
  },
});

export const createStrategyConfigurationErrorEvent = createEvent<AxiosError>();

export const deleteStrategyConfigurationEvent = createEvent<{
  strategyConfiguration: StrategyConfiguration | undefined;
}>({
  initialValue: undefined,
});

export const deleteStrategyConfigurationErrorEvent = createEvent<AxiosError>();

export const createStrategyTimeEvent = createEvent<{
  strategy: Strategy | undefined;
}>({
  initialValue: {
    strategy: undefined,
  },
});

export const createStrategyTimErrorEvent = createEvent<AxiosError>();

export const deleteStrategyTimeEvent = createEvent<{
  strategy: Strategy | undefined;
}>({
  initialValue: {
    strategy: undefined,
  },
});

export const deleteStrategyTimErrorEvent = createEvent<AxiosError>();

export const fetchStrategyPairsEvent = createEvent<{ strategies: Strategy[] }>({
  initialValue: {
    strategies: [],
  },
});

export const fetchStrategyPairsErrorEvent = createEvent<AxiosError>();
