import { OrderField } from './order-types';

export const ORDER_ACTIVITY_SHORT_COLUMNS = [
  { name: 'Pair', xs: 4, field: OrderField.Pair },
  { name: 'Amount', xs: 4, field: OrderField.Amount },
  { name: 'Action', xs: 4, field: OrderField.Action },
];

export const ORDER_ACTIVITY_LONG_COLUMNS = [
  { name: 'Pair', xs: 3, field: OrderField.Pair },
  { name: 'Position', xs: 4, field: OrderField.Position },
  { name: 'Amount', xs: 2, field: OrderField.Amount },
  { name: 'Profit Margin (%)', xs: 2, field: OrderField.Profit },
  { name: 'Action', xs: 1, field: OrderField.Action },
];



export const ORDER_HISTORY_SHORT_COLUMNS = [
  { name: 'Pair', xs: 4, field: OrderField.Pair },
  { name: 'Date / Time', xs: 4, field: OrderField.Date },
  { name: 'Amount', xs: 4, field: OrderField.Amount },
];

export const ORDER_HISTORY_LONG_COLUMNS = [
  { name: 'Pair', xs: 2, field: OrderField.Pair },
  { name: 'Date / Time', xs: 2, field: OrderField.Date },
  { name: 'Position', xs: 2, field: OrderField.Position },
  { name: 'Pips', xs: 2, field: OrderField.Pips },
  { name: 'Amount', xs: 2, field: OrderField.Amount },
  { name: 'Profit Margin (%)', xs: 2, field: OrderField.Profit },
];



