import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';

import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { fetchUserEvent } from '../../../auth/auth-events';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { updateMeta } from '../../../meta/meta-actions';
import { changeStep } from '../../onboarding-actions';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#44DD29!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#44DD29!important',
  },
  height: 40,
});

export const CreateAccount: React.FC = () => {
  const { user } = useEvent(fetchUserEvent);

  const [callUpdateMeta, loadingMeta] = useCallAction(updateMeta, {
    onCompleted: () => {
      changeStep(2);
    },
  });

  const onChangeStep = (): void => {
    const meta = user?.meta.find((_meta) => _meta.key === 'on_boarding_step');
    if (meta)
      callUpdateMeta({ id: meta.id, key: 'on_boarding_step', value: '2' });
  };

  return (
    <Box display="flex">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        px={10}
      >
        <Grid item sm={3} marginTop={'50px'}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              marginBottom={'10px'}
              display={'flex'}
              justifyContent={'center'}
            >
              <img
                src={'./ihuw-logo.svg'}
                alt={'logo'}
                width={'200px'}
                height={'80px'}
                style={{ objectFit: 'contain' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign={'center'} fontWeight={600} fontSize={24}>
                Create your account
              </Typography>
              <Typography textAlign={'center'} fontSize={16}>
                Generate profits with our automated forex bot
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={() => onChangeStep()}
                isLoading={loadingMeta}
                fullWidth
              >
                Continue
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
