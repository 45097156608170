import React, { ReactElement, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SETTING_ROUTES } from '../setting-utils';
import { makeStyles } from '@mui/styles';
import { RoleAuthorizationAdmin } from '../../rbca/RoleAuthorization';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles(() => ({
  listRoot: {
    height: '100%',
    padding: 0,
  },
  listItem: {
    '&.Mui-selected': {
      backgroundColor: '#000000!important',
      color: 'white',
    },
    borderRadius: 10,
    marginBottom: 10,
    backgroundColor: 'transparent!important',
    color: 'black',
  },
  settingListItem: {
    backgroundColor: '#f5f5f5',
    marginBottom: 10,
    borderRadius: 10,
  },
  listItemText: {
    '& > .MuiTypography-root': {
      fontWeight: '700!important',
    },
  },
  title: {
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 10,
    fontWeight: 'bold',
  },
}));

type SettingLayoutProps = {
  children: ReactElement;
};

export const SettingLayout: React.FC<SettingLayoutProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const currentRoute = SETTING_ROUTES.find(
    (route) => route.path === location.pathname,
  );

  return (
    <Box height="100%">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        height="100%"
      >
        <Grid xs={12} sx={{ display: { md: 'none', xs: 'block' } }} item>
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Settings
            </AccordionSummary>
            <AccordionDetails>
              <List
                component="nav"
                classes={{
                  root: classes.listRoot,
                }}
              >
                {SETTING_ROUTES.map((route, key) => {
                  if (route.checkRole) {
                    return (
                      <RoleAuthorizationAdmin
                        render={() => (
                          <ListItemButton
                            key={key}
                            className={classes.listItem}
                            selected={route.path === location.pathname}
                            onClick={() => navigate(route.path)}
                            disableRipple
                            disableTouchRipple
                          >
                            <ListItemText
                              primary={route.name}
                              className={classes.listItemText}
                            />
                          </ListItemButton>
                        )}
                        error={() => null}
                      />
                    );
                  }

                  return (
                    <ListItemButton
                      key={key}
                      className={classes.listItem}
                      selected={route.path === location.pathname}
                      onClick={() => navigate(route.path)}
                      disableRipple
                      disableTouchRipple
                    >
                      <ListItemText
                        primary={route.name}
                        className={classes.listItemText}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid xs={2} item sx={{ display: { md: 'block', xs: 'none' } }}>
          <Paper variant="outlined">
            <Box p={2}>
              <List
                component="nav"
                classes={{
                  root: classes.listRoot,
                }}
              >
                <ListItemButton className={classes.settingListItem}>
                  <ListItemText
                    primary={'Settings'}
                    className={classes.listItemText}
                  />
                </ListItemButton>
                {SETTING_ROUTES.map((route, key) => {
                  if (route.checkRole) {
                    return (
                      <RoleAuthorizationAdmin
                        render={() => (
                          <ListItemButton
                            key={key}
                            className={classes.listItem}
                            selected={route.path === location.pathname}
                            onClick={() => navigate(route.path)}
                            disableRipple
                            disableTouchRipple
                          >
                            <ListItemText
                              primary={route.name}
                              className={classes.listItemText}
                            />
                          </ListItemButton>
                        )}
                        error={() => null}
                      />
                    );
                  }

                  return (
                    <ListItemButton
                      key={key}
                      className={classes.listItem}
                      selected={route.path === location.pathname}
                      onClick={() => navigate(route.path)}
                      disableRipple
                      disableTouchRipple
                    >
                      <ListItemText
                        primary={route.name}
                        className={classes.listItemText}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={isMd ? 10 : 12} height="100%">
          <Box height="100%">
            <Paper style={{ height: '100%' }} variant="outlined">
              <Box p={2}>
                <Box className={classes.title} mb={2}>
                  {currentRoute?.name}
                </Box>
                <Box>{children}</Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
