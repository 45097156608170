import { createContext } from 'react';
import { StreamingMetaApiConnectionInstance } from 'metaapi.cloud-sdk';

export type DefaultValuesContextType = {
  isConnectedToBroker?: boolean;
  accountId?: string;
  connection?: StreamingMetaApiConnectionInstance;
};

const defaultValues = {
  isConnectedToBroker: false,
  connection: undefined,
  accountId: '',
};

export const Context = createContext<DefaultValuesContextType>(defaultValues);

export const { Consumer } = Context;
export const { Provider } = Context;
