import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from '../../shared/components/Loading';
import { useLocation } from 'react-router-dom';

export const Auth: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  useEffect(() => {
    loginWithRedirect({
      appState: {
        redirectTo: location.state.redirectTo,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Loading />;
};
